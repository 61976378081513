import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { generatePath, useNavigate } from "react-router-dom";
import { addFriend, fetchAllConnections } from "../../lib/api/friends";
import { setAllConnections } from "../../features/user/user-slice";
import { LocationModel, UserModel } from "../../lib/types";
import { routes } from "../../config/routes";
import LoadingSpinner from "../../components/LoadingSpinner";
import { SubView } from "./SubView";
import { IoChevronBack } from "react-icons/io5";
import { fetchUserById } from "../../lib/api/users";
import CircleAvatar from "../../components/CircleAvatar";
import { emit } from "../../lib/utils/event-bus";
import { PhotoUrl } from "../../lib/utils/photo-url";
import { scopedLogger } from "../../lib/utils/logger";

import { SubViewHeader } from "./SubViewHeader";
import {
  disableTwib,
  fetchAllTwibsCreatedByUser,
  fetchTwibsCreatedByOtherUser,
} from "../../lib/api/twibs";
import { setUserTwibs } from "../../features/user/user-slice";
import { TwibModel } from "../../lib/types";
import {
  iconForVisibilityType,
  paymentTypeDetails,
  twibStatus,
  visibilityByType,
} from "../../lib/dictionary";
import { DateTime } from "luxon";
import { Tooltip } from "react-tooltip";
import { twibDetailDate, twibDetailTime } from "../../lib/utils/date";
import { Button } from "flowbite-react";
import { MdArrowBackIos } from "react-icons/md";
import { imageSource } from "../../components/LocationMarker";
import { filterAcceptedUsers } from "../../lib/services/chat-users";
import { useSelector } from "react-redux";
import { matchedLocations } from "../../lib/api/locations";
import { setLocations } from "../../features/locations/locations-slice";
import { submitRSVP } from "../../lib/api/message-threads";

const logger = scopedLogger("OtherUserSubview");

const expiredTwibsOnly = (twib: TwibModel): boolean => {
  if (twib.twib_date === null) {
    return false;
  }

  const twibDate = DateTime.fromISO(twib.twib_date, { zone: twib.timezone });

  return DateTime.now() > twibDate;
};

const activeTwibsOnly = (twib: TwibModel): boolean => {
  return !expiredTwibsOnly(twib) && twib.status === twibStatus.active;
};

const inactiveTwibsOnly = (twib: TwibModel): boolean => {
  return !expiredTwibsOnly(twib) && twib.status === twibStatus.inactive;
};

export default function OtherUserSubview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const [connectionsFetched, setConnectionsFetched] = useState<boolean>(false);
  const [hasFetchedTwibs, setHasFetchedTwibs] = useState<boolean>(false);
  const [isInitiallyLoading, setIsInitiallyLoading] = useState<boolean>(true);
  const [otherUser, setOtherUser] = useState<UserModel | null>(null);
  const [otherUserTwibs, setOtherUserTwibs] = useState<TwibModel[]>([]);
  const appUser = useAppSelector((state) => state.user.user);
  const connections = useAppSelector((state) => state.user.connections);
  const loading = !connectionsFetched || !hasFetchedTwibs || isInitiallyLoading;
  const isMe = appUser?.id === otherUser?.id;

  logger("from OtherUserSubview", userId);

  useEffect(() => {
    if (!connectionsFetched) {
      fetchAllConnections().then((connections) => {
        dispatch(setAllConnections(connections));
        setConnectionsFetched(true);
      });
    }
  }, [connectionsFetched, dispatch]);

  const isFollowing = (userId: number): boolean => {
    return connections.following.some((connection) => connection.id === userId);
  };

  const isFriends = (userId: number): boolean => {
    return connections.friends.some((connection) => connection.id === userId);
  };

  const isFriendsOrIsFollowing = (userId: number): boolean => {
    return isFollowing(userId) || isFriends(userId);
  };

  useEffect(() => {
    fetchUserById(userId)
      .then((user) => {
        setOtherUser(user);
      })
      .finally(() => {
        setIsInitiallyLoading(false);
      });
  }, [isInitiallyLoading, userId]);

  useEffect(() => {
    if (otherUser?.id && !hasFetchedTwibs) {
      fetchTwibsCreatedByOtherUser(otherUser).then((twibs) => {
        setOtherUserTwibs(twibs);
        setHasFetchedTwibs(true);
      });
    }
  }, [dispatch, otherUser, hasFetchedTwibs]);

  logger({ otherUser });

  const activeTwibsOnly = (twib: TwibModel): boolean => {
    return !expiredTwibsOnly(twib) && twib.status === twibStatus.active;
  };

  const activeTwibs = otherUserTwibs.filter(activeTwibsOnly);

  const [isAddingFriend, setIsAddingFriend] = useState<boolean>(false);
  const [friendAdded, setFriendAdded] = useState<boolean>(false);
  const handleAddFriend = async (user: UserModel) => {
    setIsAddingFriend(true);

    const result = await addFriend(user);
    dispatch(setAllConnections(result));

    setFriendAdded(true);
    setIsAddingFriend(false);
  };

  if (loading) {
    return (
      <SubView>
        <div
          style={{ height: 60 }}
          className={"bg-primary p-4 top-0 bottom-0 right-0 left-0"}
        >
          <div className={"bg-primary rounded-xl p-2 mt-16"}>
            <div
              className={
                "flex flex-row items-center w-full text-center bg-white rounded text-primary"
              }
              style={{
                minHeight: "calc(30vh)",
                overflow: "scroll",
                justifyContent: "center",
              }}
            >
              <LoadingSpinner />
            </div>
          </div>
        </div>
      </SubView>
    );
  }

  const notFriends = !isFriendsOrIsFollowing(otherUser!.id);

  const inviteButton = notFriends ? (
    <Button
      size="xs"
      loading={isAddingFriend}
      onClick={() => handleAddFriend(otherUser!)}
    >
      <p>Add Friend</p>
    </Button>
  ) : (
    <>
      {friendAdded ? (
        <p className="text-white text-xs mt-2">✓ Added</p>
      ) : (
        <div style={{ width: "40px" }} />
      )}
    </>
  );

  // filter out twibs that are private if !notFriends
  // and everything except public if notFriends
  // TODO: technically this logic should be more fine-tuned
  // so that you if you have a mutual friend you can still
  // see the friends of friends twibs when you're notFriends
  // and if !notFriends then you should see private ones that you
  // are invited to
  const notFriendsFilter = (t: TwibModel) => t.visibility_type === 1;
  const friendsFilter = (t: TwibModel) => t.visibility_type < 4;
  const twibsToRender = activeTwibs.filter(
    isFriends(otherUser?.id || NaN) ? friendsFilter : notFriendsFilter
  );

  return (
    <SubView>
      <div
        style={{ height: 60 }}
        className={"bg-primary p-4 top-0 bottom-0 right-0 left-0"}
      >
        <div style={{ fontSize: 20 }} className="flex flex-row justify-between">
          <div style={{ marginTop: "-6px", marginLeft: "-6px" }}>
            <Button onClick={() => navigate(-1)} color={"none"} className={""}>
              <MdArrowBackIos className={"text-white text-xl"} />
            </Button>
          </div>
          <div>{`${otherUser?.first_name}'s Profile`}</div>
          {isMe ? <div style={{ width: "40px" }} /> : inviteButton}
        </div>
        <div className={"bg-primary rounded-xl mt-8"}>
          <div
            className={"bg-primary rounded-xl text-primary"}
            style={{
              maxHeight: "calc(100vh - 120px)",
              overflow: "scroll",
            }}
          >
            <div className="justify-center flex flex-row">
              <img
                src={PhotoUrl.user(otherUser).thumb}
                alt={PhotoUrl.user(otherUser).thumb}
                className={"rounded-full mt-3"}
                style={{ height: "100px", width: "100px" }}
              />
            </div>
            <div className="justify-center flex flex-row items-center mt-3">
              <div
                style={{
                  fontWeight: 600,
                }}
                className="text-l text-white mr-2"
              >
                {otherUser?.first_name}
              </div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "grey",
                }}
                className="text-l text-primary"
              >
                @{otherUser?.username}
              </div>
            </div>
            <div
              className="my-3"
              style={{
                background: "rgb(255, 255, 255, 0.4)",
                height: "1px",
              }}
            />
            <div>
              {!twibsToRender.length ? (
                <h2 className="mt-8 text-primary text-xl ml-16 mr-16 mb-8 text-center">
                  {otherUser?.first_name} doesn't have any twibs
                </h2>
              ) : (
                <>
                  <h2 className="mt-4 text-white text-sm ml-4 text-start">
                    {otherUser?.first_name}'s Twibs:
                  </h2>
                  {twibsToRender.map((t, i) => (
                    <Fragment key={i}>
                      <OtherUserSubviewTwibDetails twib={t} />
                    </Fragment>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </SubView>
  );
}

export const OtherUserSubviewTwibDetails = ({ twib }: { twib: TwibModel }) => {
  const navigate = useNavigate();
  const appUser = useAppSelector((state) => state.user.user);
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const handleShowMessages = () => {
    navigate(generatePath(routes.map, { view: "messages", id: twib?.chat.id }));
  };

  const handleRsvp = (rsvp: "accept" | "decline") => {
    return async () => {
      submitRSVP(twib!.chat, rsvp).then((res) => {
        if (rsvp === "accept") {
          handleShowMessages();
        }
      });
    };
  };

  const coverImage: string | null = twib?.image_uri || null;

  const userHasRsvpd =
    (twib !== null &&
      twib.chat?.users?.length > 0 &&
      twib.chat.users
        .filter(filterAcceptedUsers)
        .filter((u: UserModel) => u.id === appUser?.id).length > 0) ||
    false;

  return (
    <div
      style={{ padding: "12px", margin: "10px" }}
      className={"flex-grow bg-primary-light rounded-2xl"}
    >
      <div className={"p-3 bg-white rounded-2xl"}>
        <div className={"flex flex-row justify-between font-bold mb-3"}>
          <div className={"flex flex-row items-center"}>
            <img
              src={imageSource(twib.location)}
              alt="location"
              className={"rounded-full w-9 h-9 border border-lime-300"}
            />
            <div
              style={{
                fontSize: 14,
                maxWidth: "55%",
                marginLeft: "8px",
              }}
            >
              {twib.location.name}
            </div>
            <div
              style={{
                marginLeft: "8px",
              }}
            >
              <div
                data-tooltip-id="vistype"
                data-tooltip-content={visibilityByType(twib.visibility_type)}
              >
                {iconForVisibilityType(twib.visibility_type)}
              </div>
              <Tooltip place="bottom" id="vistype" />
            </div>
          </div>
          <div
            className={"flex flex-row justify-start items-center"}
            style={{
              fontSize: 16,
            }}
          >
            <span className={"mr-2"}>
              {paymentTypeDetails[twib.type.name].icon}
            </span>
            <span
              style={{
                fontSize: 14,
              }}
            >
              {twib.type.name}
            </span>
          </div>
        </div>
        {coverImage && (
          <img
            onClick={() => setFullscreen(!fullscreen)}
            style={{
              height: fullscreen ? "fit-content" : "100px",
            }}
            className={"w-full object-cover"}
            src={coverImage}
            alt="location"
          />
        )}
        {twib.description && (
          <div
            style={{ background: "#2626260D", padding: "12px 8px" }}
            className={"flex flex-row justify-around"}
          >
            {twib.description}
          </div>
        )}
        <div
          style={{ background: "#2626260D", padding: "12px 8px" }}
          className={"flex flex-row justify-around items-center mt-2"}
        >
          <div className={"flex flex-row justify-start items-center mr-2"}>
            🗓&nbsp;&nbsp;
            {twibDetailDate(twib)}
          </div>
          <div
            style={{
              height: "100%",
              padding: "10px 0px",
              width: "1px",
              background: "#2626264D",
            }}
          />
          <div className={"flex flex-row justify-start items-center"}>
            ⏰&nbsp;&nbsp;
            {twibDetailTime(twib)}
            &nbsp;
          </div>
        </div>
      </div>
      <div
        style={{
          width: "-webkit-fill-available",
          height: "100%",
          padding: "12px",
          margin: "0px 10px 0px 10px",
          background: "#AFBBF2",
          borderRadius: "24px",
          justifyContent: "center",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <div
          style={{
            color: "#262626",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20.80px",
            wordWrap: "break-word",
          }}
        >
          <button
            onClick={userHasRsvpd ? handleShowMessages : handleRsvp("accept")}
          >
            {userHasRsvpd ? "View Chat" : "Join"}
          </button>
        </div>
      </div>
    </div>
  );
};
