import { useRef } from "react";
import { Button, Label, TextInput } from "flowbite-react";

type SetTimeProps = {
  time: string | null;
  onTimeSet: (time: string | null) => void;
};
export const SetTime = ({ time, onTimeSet }: SetTimeProps) => {
  const timeRef = useRef<HTMLInputElement>(null);

  const handleClear = () => {
    onTimeSet(null);
  };
  const handleSet = () => {
    onTimeSet(timeRef.current?.value || null);
  };

  return (
    <div>
      <div className={"mb-2"}>
        <Label>Start Time</Label>
        <TextInput ref={timeRef} type={"time"} defaultValue={time || ""} />
      </div>
      <div className={"flex flex-row"}>
        <Button outline onClick={handleClear} className={"flex-1 mr-2"}>
          {time === null ? "Cancel" : "Clear"}
        </Button>
        <Button onClick={handleSet} className={"flex-1"}>
          Set
        </Button>
      </div>
    </div>
  );
};
