import { Routes, Route, useLocation } from "react-router";
import MapView from "./views/MapView";
import LoginView from "./views/LoginView";
import { useEffect, useState } from "react";
import { auth } from "./config/firebase";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { fetchUser, refreshPushToken } from "./lib/api/users";
import { setApiUser, setUserAuth } from "./features/user/user-slice";
import { useAppDispatch, useAppSelector } from "./state/hooks";
import RootView from "./views/RootView";
import SignupView from "./views/SignupView";
import { fetchGenders } from "./lib/api/genders";
import { setGenders } from "./features/genders/genders-slice";
import { getSocket } from "./config/sockets";
import { UserModel, WebsocketEvent } from "./lib/types";
import { setWebsocketConnected } from "./features/app-slice";
import * as eventBus from "./lib/utils/event-bus";
import EditProfileView from "./views/profile/EditProfileView";
import { useRollbar } from "@rollbar/react";
import { Flowbite } from "flowbite-react";
import { routes } from "./config/routes";
import { appTheme } from "./config/theme";
import Onboarding from "./views/Onboarding";
import tracking from "./lib/api/tracking";
import Install from "./views/Install";
import SplashScreenLoading from "./components/SplashScreenLoading";
import { emit } from "./lib/utils/event-bus";
import { scopedLogger } from "./lib/utils/logger";

const logger = scopedLogger("App");

export default function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const rollbar = useRollbar();
  const userAuth = useAppSelector((state) => state.user.auth);
  const [apiFetchedUser, setApiFetchedUser] = useState<UserModel | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [userInitialized, setUserInitialized] = useState<boolean>(false);
  const isLoginPage = location.pathname === routes.login;

  useEffect(() => {
    emit("set-rollbar", rollbar);
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        tracking.appBackgrounded();
      } else {
        tracking.appForegrounded();
      }
    });
  }, []);

  // register app background/foreground events: []
  useEffect(() => {
    if (isLoginPage) {
      return;
    }
    // only used to make sure we always have the latest user auth values
    const unregisterOnIdTokenChanged = auth.onIdTokenChanged((user) => {
      logger("onIdTokenChanged", user);

      if (user !== null && userAuth !== null) {
        logger("App.onIdTokenChanged", "firebase user is not null");
        // update auth token if it changes
        user.getIdToken().then((accessToken) => {
          logger("App.onIdTokenChanged", "dispatching setUserAuth");
          dispatch(
            setUserAuth({
              user: user!,
              accessToken,
              uid: user!.uid,
            })
          );
        });
      }
    });

    const unregisterAuthCallback = auth.onAuthStateChanged((user) => {
      logger("auth state changed", userAuth, user);

      // was signed in, but now signed out
      if (user === null && userAuth !== null) {
        logger("user is null but userAuth wasn't null");
        dispatch(setApiUser(null));
        navigate(routes.login);
        return;
      }

      // we're signed in, but haven't yet set the userAuth
      if (user !== null && userAuth === null) {
        logger("user is not null but userAuth is null, setting userAuth");
        user.getIdToken().then((accessToken) => {
          logger("App.onAuthStateChanged", "dispatching setUserAuth");
          dispatch(
            setUserAuth({
              user: user!,
              accessToken,
              uid: user!.uid,
            })
          );
        });
        return;
      }

      if (auth.currentUser === null && userAuth === null) {
        logger(
          "App.onAuthStateChanged",
          "user is null, useAuth is null, navigating to login"
        );
        navigate(routes.login);
        setIsInitialized(true);
        return;
      }
    });

    return () => {
      unregisterOnIdTokenChanged();
      unregisterAuthCallback();
    };
  }, [isLoginPage]);

  useEffect(() => {
    if (userAuth === null) {
      logger("user auth is null, returning", auth.currentUser);
      return;
    }

    const unregisterFunctions: any[] = [];
    fetchUser()
      .then(async (apiUser: UserModel) => {
        const socket = getSocket(
          apiUser,
          () => {
            dispatch(setWebsocketConnected("connected"));
          },
          () => {
            dispatch(setWebsocketConnected("disconnected"));
          },
          (event: WebsocketEvent) => {
            eventBus.emit<WebsocketEvent>(event.event, event);
            logger("GOT EVENT OF TYPE", event.event);
          }
        );
        unregisterFunctions.push(() => {
          logger("closing socket now...");
          socket.close();
        });

        // await getFirebasePushToken();
        setApiFetchedUser(apiUser);

        dispatch(setApiUser(apiUser));

        logger("newUser", apiUser.status);
        if (apiUser.status === "created") {
          logger("App.tsx: user needs to signup");
          setUserInitialized(true);
          return navigate(routes.signup);
        }

        logger("App.tsx: user is logged in and has an account");

        await Promise.all([
          tracking.setUser(apiUser).appOpened(),
          fetchGenders().then((genders) => {
            dispatch(setGenders(genders));
          }),
          refreshPushToken(),
        ]);
        setUserInitialized(true);
      })
      .catch((err) => {
        logger("ERRRROR", err);
        rollbar.error("Unable to log in", err, {
          firebase_uid: userAuth.user?.uid,
        });
        auth.signOut();
        navigate(routes.login);
      });

    return () => unregisterFunctions.forEach((fn) => fn());
  }, [userAuth]);

  // check if the user is initialized: [appInstalled, userInitialized]
  useEffect(() => {
    if (userInitialized && !isInitialized) {
      setIsInitialized(true);
    }
    if (location.pathname === routes.login) {
      setIsInitialized(true);
    }
  }, [userInitialized]);

  logger("is initialized", isInitialized);
  return (
    <Flowbite theme={appTheme}>
      <div className="App">
        {!isInitialized && <SplashScreenLoading />}
        {isInitialized && (
          <Routes>
            <Route path={routes.root} element={<RootView />} />
            <Route path={routes.map} element={<MapView />} />
            <Route path={routes.login} element={<LoginView />} />
            <Route path={routes.onboarding} element={<Onboarding />} />
            <Route path={routes.editProfile} element={<EditProfileView />} />
            <Route path={routes.signup} element={<SignupView />} />
            <Route path={routes.install} element={<Install />} />
          </Routes>
        )}
      </div>
    </Flowbite>
  );
}
