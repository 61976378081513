export const routes = {
  root: "/",
  map: "/map/:view?/:id?",
  mapRoot: "/map",
  login: "/login",
  messages: "/map/messages",
  messageThread: "/messages/threads/:threadId",
  onboarding: "/onboarding",
  install: "/install",
  people: "/map/people/:tab?",
  editProfile: "/profile/edit",
  profile: "/map/profile",
  settings: "/settings",
  signup: "/signup",
};
