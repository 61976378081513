import { FilterTimeframe, FilterVisibility, TwibModel } from "../lib/types";
import { PhotoUrl } from "../lib/utils/photo-url";
import classNames from "classnames";
import { RaceBy } from "@uiball/loaders";

import Menu from "./Menu";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { filters, visibilityTypes } from "../lib/dictionary";
import {
  setTimeframeFilter,
  setVisibilityFilter,
} from "../features/map/map-slice";
import { BsChevronCompactUp } from "react-icons/bs";
import { dateForTwib, twibDetailDate } from "../lib/utils/date";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "../config/routes";
import { setIsMenuOpen } from "../features/app-slice";
import { DropdownButton } from "./DropdownButton";
import { motion, AnimatePresence } from "framer-motion";
import { twibsFromLocations } from "../features/map/twibs-from-locations";

const TwibDetails = ({
  twib,
  onClick,
}: {
  twib: TwibModel;
  onClick: () => void;
}) => {
  const appUser = useAppSelector((state) => state.user.user);
  let invitedText =
    twib.user_id === appUser!.id
      ? "You created this twib"
      : `Invited by ${twib.user.first_name}`;
  if (
    twib.visibility_type === visibilityTypes.public &&
    twib.user_id !== appUser!.id
  ) {
    invitedText = `Public TWIB`;
  }
  return (
    <div className={"p-2 min-w-[14rem]"} onClick={onClick}>
      <div className={"rounded"}>
        <img
          className={"object-cover max-h-24 w-full rounded-t"}
          src={PhotoUrl.twib(twib)}
          alt="whatever"
        />
        <div className={"p-1"}>
          <div>{twibDetailDate(twib)}</div>
          <div className={"font-bold"}>{twib.location.name}</div>
          <div className={"font-light text-sm text-gray-400"}>
            {invitedText}
          </div>
        </div>
      </div>
    </div>
  );
};

export type TwibTrayProps = {
  twibs: TwibModel[];
  isOpen: boolean;
  onReOpen: () => void;
  isLoading: boolean;
};
export default function TwibTray({
  twibs,
  isOpen,
  onReOpen,
  isLoading,
}: TwibTrayProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMenuOpen = useAppSelector((state) => state.app.isMenuOpen);
  const visibilityFilter = useAppSelector(
    (state) => state.map.filters.visibility
  );
  const timeframeFilter = useAppSelector(
    (state) => state.map.filters.timeframe
  );
  const { visibilities, timeframes } = filters;
  const appUser = useAppSelector((state) => state.user.user);
  const allTwibs = twibsFromLocations(
    useAppSelector((state) => state.map.locations)
  );

  const handleSetVisibility = (filterVisibility: FilterVisibility) => {
    dispatch(setVisibilityFilter(filterVisibility));
  };
  const handleSetTimeframe = (filterTimeframe: FilterTimeframe) => {
    dispatch(setTimeframeFilter(filterTimeframe));
  };

  const Closed = () => {
    return (
      <div
        className={
          "w-full h-12 flex flex-row justify-center items-center bg-white_ border-t-4 border-gray-500 bg-gradient-permissions"
        }
        onClick={onReOpen}
      >
        {/*<BsDashLg className={"font-bold text-2xl"} />*/}
        <BsChevronCompactUp className={"font-bold text-2xl"} />
      </div>
    );
  };

  // make sure we're all good and logged in
  if (!appUser) return null;

  twibs.sort((a: TwibModel, b: TwibModel) => {
    const aDate = dateForTwib(a);
    const bDate = dateForTwib(b);

    if (!aDate && !bDate) return 0;
    if (!aDate) return 1;
    if (!bDate) return -1;

    if (aDate < bDate) {
      return -1;
    }
    if (aDate > bDate) {
      return 1;
    }
    return 0;
  });

  return (
    <div className={"fixed bottom-0 left-0 right-0"}>
      <Menu user={appUser!} />
      {!isOpen && <Closed />}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.2 }}
            exit={{ height: 0 }}
            className={
              "bg-white_ .z-50 pt-2 px-2 border-t-4 border-gray-500 bg-gradient-permissions"
            }
          >
            {/* filters */}
            {allTwibs.length > 0 && (
              <div
                className={"flex flex-row justify-between items-center mt-1"}
              >
                <div className={"font-bold"}>Twibs For You</div>
                <div className={"flex flex-row justify-end items-center"}>
                  <DropdownButton
                    disabled={false}
                    outline={false}
                    options={visibilities.list.map((v) => v.label)}
                    selectedOption={visibilityFilter.label}
                    onSelectOption={(selected: string) => {
                      const visibility = visibilities.list.find(
                        (v) => v.label === selected
                      )!;
                      handleSetVisibility(visibility);
                    }}
                  />
                  <DropdownButton
                    disabled={false}
                    outline={false}
                    options={timeframes.list.map((t) => t.label)}
                    selectedOption={timeframeFilter.label}
                    onSelectOption={(selected: string) => {
                      const timeframe = timeframes.list.find(
                        (t) => t.label === selected
                      )!;
                      handleSetTimeframe(timeframe);
                    }}
                  />
                </div>
              </div>
            )}
            {/* twib list*/}
            <div
              className={classNames("flex flex-row overflow-x-scroll", {
                "min-h-[150px]": twibs.length > 0,
                "min-h-[125px]": twibs.length === 0,
              })}
            >
              {twibs.length === 0 && (
                <div
                  className={classNames("pl-1 mt-4 w-full", {
                    "text-center": !isLoading,
                    "text-sm": !isLoading,
                    "text-gray-500": !isLoading,
                  })}
                >
                  {isLoading ? (
                    <div
                      style={{
                        margin: "20px auto",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <RaceBy
                        size={80}
                        lineWeight={5}
                        speed={1.4}
                        color="white"
                      />
                    </div>
                  ) : (
                    "Your upcoming twibs will show up here!"
                  )}
                </div>
              )}
              {!isLoading &&
                twibs.map((twib) => (
                  <TwibDetails
                    key={`twib-list-${twib.id}`}
                    twib={twib}
                    onClick={() => {
                      navigate(
                        generatePath(routes.map, {
                          view: "twib",
                          id: twib.id.toString(),
                        })
                      );
                      dispatch(setIsMenuOpen(false));
                      // dispatch(setSelectedTwib(twib));
                    }}
                  />
                ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
