import {
  NewTwibLocationModel,
  PaymentTypeModel,
  VisibilityType,
  UserModel,
  TwibModel,
} from "../../lib/types";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { fetchPaymentTypes } from "../../lib/api/payment-types";
import { Button, Modal, Textarea } from "flowbite-react";
import { LiaCalendarAltSolid, LiaClock } from "react-icons/lia";
import { DateTime } from "luxon";
import classNames from "classnames";
import { CiImageOn } from "react-icons/ci";
import { visibilityTypeOptions, visibilityTypes } from "../../lib/dictionary";
import { createTwib } from "../../lib/api/twibs";
import PhotoStorageService from "../../lib/services/photo-storage-service";
import LoadingSpinner from "../LoadingSpinner";
import { SetInvites } from "./SetInvites";
import { SelectRow } from "./SelectRow";
import { SetPaymentType } from "./SetPaymentType";
import { SetTime } from "./SetTime";
import { SetDate } from "./SetDate";
import { AiOutlineClose } from "react-icons/ai";
import { MdArrowBackIos } from "react-icons/md";

type DetailStepProps = {
  location: NewTwibLocationModel;
  onTwibAdded: (twib: TwibModel) => void;
  handleClose: () => void;
};
export default function DetailsStep({
  location,
  onTwibAdded,
  handleClose,
}: DetailStepProps) {
  const [state, setState] = useState<
    "default" | "date" | "time" | "payment" | "invite"
  >("default");
  const [date, setDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const [paymentTypes, setPaymentTypes] = useState<PaymentTypeModel[] | null>(
    null
  );
  const [description, setDescription] = useState<string>("");
  const [paymentType, setPaymentType] = useState<PaymentTypeModel | null>(null);
  const [photo, setPhoto] = useState<File | null>(null);
  const [visibilityType, setVisibilityType] = useState<VisibilityType>(
    visibilityTypes.public
  );
  const [invites, setInvites] = useState<UserModel[] | null>(null);
  const [isAddingTwib, setIsAddingTwib] = useState<boolean>(false);

  useEffect(() => {
    if (paymentTypes === null) {
      fetchPaymentTypes().then((types: PaymentTypeModel[]) => {
        setPaymentTypes(types);
        // const defaultType = types.find((type) => type.is_default);
        const defaultType = types.find((type) => type.name === "Splitsies");
        setPaymentType(defaultType || null);
      });
    }
  }, [paymentTypes]);

  const handleFileSelected = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    // @ts-ignore
    const files = Array.from(e.target.files);
    setPhoto(files[0]);
  };

  const handleAddTwib = async () => {
    setIsAddingTwib(true);

    let imageUrl: string = "";
    if (photo instanceof File) {
      const fileUrls = await PhotoStorageService.storePhoto(photo!);
      imageUrl = fileUrls.storageUrl;
    }

    const newTwib = await createTwib(
      {
        visibility_type: visibilityType!,
        location: location,
        description,
        twib_date: date,
        twib_time: time,
        payment_type_id: paymentType!.id,
        image_uri: imageUrl,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      invites || []
    );

    onTwibAdded(newTwib);
  };

  const handleSetPaymentType = (paymentType: PaymentTypeModel | null) => {
    setPaymentType(paymentType);
    setState("default");
  };

  const handleSetDate = (date: string | null) => {
    setDate(date);
    setState("default");
  };

  const handleSetTime = (time: string | null) => {
    setTime(time);
    setState("default");
  };

  const handleSetInvites = (
    type: VisibilityType,
    users: UserModel[] | null
  ) => {
    setVisibilityType(type);
    setInvites(users);
    setState("default");
  };

  const formatDate = (date: string | null) => {
    if (date === null) {
      return "Any Day";
    }

    return DateTime.fromISO(date).toFormat("ccc, LLL d");
  };

  const formatTime = (time: string | null) => {
    if (time === null) {
      return "Any Time";
    }

    return DateTime.fromISO(time).toFormat("h:mm a").toLowerCase();
  };

  const inviteBadgeLabel = (): string | null => {
    const type =
      visibilityTypeOptions.find((t) => t.value === visibilityType) || null;

    if (type === null) {
      return null;
    }

    if (type.value === visibilityTypes.private) {
      return `${type.label} (${invites?.length})`;
    }

    return type.label;
  };

  return (
    <>
      {state === "default" && (
        <Button
          onClick={handleClose}
          color={"none"}
          className={"absolute top-2 right-0"}
        >
          <AiOutlineClose className={"text-gray-500 text-xl"} />
        </Button>
      )}
      {state !== "default" && (
        <Button
          onClick={() => setState("default")}
          color={"none"}
          className={"absolute top-2"}
        >
          <MdArrowBackIos className={"text-gray-500 text-xl"} />
        </Button>
      )}
      <Modal.Body>
        <div className="space-y-6 mb-4">
          <div className={"flex flex-col items-center"}>
            <div>I want to go to</div>
            <div className={"text-2xl text-center"}>{location.name}</div>
            <div className={"text-xs text-gray-500 mt-1 font-light"}>
              {location.address}, {location.city}
            </div>
          </div>

          {state === "default" && (
            <section>
              <div className={""}>
                <h2 className={"font-light  mb-2"}>
                  Add Details{" "}
                  <span className={"text-gray-500"}>(Optional)</span>:
                </h2>
                {/* DATE & TIME */}
                <div
                  className={"flex flex-row justify-around items-center mb-4"}
                >
                  <Button
                    color={"dark"}
                    onClick={() => setState("date")}
                    outline={date === null}
                    pill
                    className={"flex-1 mr-2"}
                  >
                    <LiaCalendarAltSolid className={"text-xl mr-2"} />
                    {date ? (
                      <span style={{ fontSize: "0.75em" }}>
                        {formatDate(date)}
                      </span>
                    ) : (
                      "Any Day"
                    )}
                  </Button>
                  <Button
                    color={"dark"}
                    onClick={() => setState("time")}
                    outline={time === null}
                    pill
                    className={"flex-1 text-xs"}
                  >
                    <LiaClock className={"text-xl mr-2"} />
                    {time ? (
                      <span style={{ fontSize: "0.75em" }}>
                        {formatTime(time)}
                      </span>
                    ) : (
                      "Any Time"
                    )}
                  </Button>
                </div>

                {/* DESCRIPTION */}
                <div className={"mb-4"}>
                  <Textarea
                    ref={descriptionRef}
                    placeholder={"Description"}
                    defaultValue={description}
                    onInput={(e) => setDescription(e.currentTarget.value)}
                  />
                </div>

                {/* PHOTO */}
                <div className={"mb-4"}>
                  {photo !== null && (
                    <div className={""}>
                      <img
                        className={"mb-2 max-h-36 w-full object-none"}
                        src={URL.createObjectURL(photo)}
                      />
                    </div>
                  )}
                  <div
                    className={classNames("w-full relative", {
                      hidden: photo !== null,
                    })}
                  >
                    <Button color={"dark"} outline={true} className={"w-full"}>
                      <CiImageOn className={"mr-2 text-xl"} />
                      <div>Add a Photo</div>
                    </Button>
                    <input
                      onChange={handleFileSelected}
                      className={
                        "block absolute opacity-0 top-0 bottom-0 right-0 left-0"
                      }
                      type="file"
                    />
                  </div>
                  <div
                    className={classNames("w-full relative", {
                      hidden: photo === null,
                    })}
                  >
                    <Button
                      color={"primary"}
                      outline={true}
                      className={"w-full"}
                      onClick={() => setPhoto(null)}
                    >
                      <CiImageOn className={"mr-2 text-xl"} />
                      <div className={"text-xs"}>Clear Photo</div>
                    </Button>
                  </div>
                </div>

                {/* PAYMENT TYPE AND INVITES */}
                <div>
                  <SelectRow
                    onClick={() => setState("payment")}
                    label={"Who's paying?"}
                    badge={paymentType?.name || null}
                    isFirst={true}
                  />
                  <SelectRow
                    onClick={() => setState("invite")}
                    label={"Visible to:"}
                    badge={inviteBadgeLabel()}
                    isFirst={false}
                  />
                </div>
              </div>
            </section>
          )}

          {state === "date" && (
            <section>
              <SetDate date={date} onDateSet={handleSetDate} />
            </section>
          )}

          {state === "time" && (
            <section>
              <SetTime time={time} onTimeSet={handleSetTime} />
            </section>
          )}

          {state === "payment" && (
            <section>
              <SetPaymentType
                paymentType={paymentType}
                paymentTypes={paymentTypes}
                setPaymentType={handleSetPaymentType}
              />
            </section>
          )}

          {state === "invite" && (
            <section>
              <SetInvites
                visibilityType={visibilityType}
                invites={invites}
                setValues={handleSetInvites}
              />
            </section>
          )}
        </div>

        {state === "default" && !isAddingTwib && (
          <div>
            <Button color={"dark"} className={"w-full"} onClick={handleAddTwib}>
              Add Twib
            </Button>
          </div>
        )}
        {state === "default" && isAddingTwib && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />
          </div>
        )}
      </Modal.Body>
    </>
  );
}
