export type CircleAvatarProps = {
  imageUri: string;
  onClick?: () => void;
};

export default function CircleAvatar({ imageUri, onClick }: CircleAvatarProps) {
  return (
    <div onClick={onClick || (() => {})}>
      <img
        className="w-14 h-14 border-2 border-blue-900 rounded-full"
        src={imageUri}
        alt="Rounded avatar"
      />
    </div>
  );
}
