import { useRef } from "react";
import { Button, Label, TextInput } from "flowbite-react";

type SetDateProps = {
  date: string | null;
  onDateSet: (date: string | null) => void;
};
export const SetDate = ({ date, onDateSet }: SetDateProps) => {
  const dateRef = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    const date = dateRef.current?.value;

    if (!date) {
      return;
    }

    onDateSet(date);
  };

  const handleClear = () => {
    onDateSet(null);
  };
  const handleSet = () => {
    onDateSet(dateRef.current?.value || null);
  };

  return (
    <div>
      <div className={"mb-2"}>
        <Label>Start Time</Label>
        <TextInput
          ref={dateRef}
          type={"date"}
          // onChange={handleChange}
          defaultValue={date || ""}
        />
      </div>
      <div className={"flex flex-row"}>
        <Button outline onClick={handleClear} className={"flex-1 mr-2"}>
          {date === null ? "Cancel" : "Clear"}
        </Button>
        <Button onClick={handleSet} className={"flex-1"}>
          Set
        </Button>
      </div>
    </div>
  );
};
