import { useState } from "react";
import { Button, Modal } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { setDialogResponse } from "../../../features/config-slice";
import { MAX_Z_INDEX } from "../../../lib/dictionary";

export default function ConfirmationDialog() {
  const dispatch = useAppDispatch();
  const dialogMessage = useAppSelector((state) => state.config.dialogMessage);

  const handleAccept = () => {
    dispatch(setDialogResponse(true));
  };

  const handleReject = () => {
    dispatch(setDialogResponse(false));
  };
  return (
    <Modal
      position="center"
      style={{
        zIndex: MAX_Z_INDEX,
        paddingTop: "20vh",
      }}
      show={dialogMessage !== null}
      popup
      onClose={() => {
        dispatch(setDialogResponse(false));
      }}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {dialogMessage}
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={handleAccept}>
              Yes, I'm sure
            </Button>
            <Button color="gray" onClick={handleReject}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
