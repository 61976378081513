import { useAppDispatch, useAppSelector } from "../../state/hooks";
import Layout from "../../components/Layout";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Button, FileInput, Label, Select, TextInput } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { fetchGenders } from "../../lib/api/genders";
import { GenderModel } from "../../lib/types";
import { setGenders } from "../../features/genders/genders-slice";
import PhotoStorageService from "../../lib/services/photo-storage-service";
import {
  fetchUser,
  updateUser,
  UpdateUserPayload,
  usernameInUse,
} from "../../lib/api/users";
import { setApiUser } from "../../features/user/user-slice";
import { routes } from "../../config/routes";
import logger from "../../lib/utils/logger";
import LoadingSpinner from "../../components/LoadingSpinner";

type ProfileErrors = {
  first_name?: string;
  username?: string;
};

const ErrorMessage = ({ msg }: { msg: string | null | undefined }) => {
  if (!msg) {
    return null;
  }
  return <span className={"text-rose-600 text-xs"}>{msg}</span>;
};

const waitForPhotoProcessing = (wait: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, wait);
  });
};

export default function EditProfileView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const firstNameRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const user = useAppSelector((state) => state.user.user);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<ProfileErrors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");

  const handleFileSelected = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    // @ts-ignore
    const files = Array.from(e.target.files);
    setPhotoFile(files[0]);
  };

  const handleSubmit = async () => {
    let hasError = false;
    const errors: ProfileErrors = {};
    setErrors({});

    if (usernameRef.current!.value.trim().length === 0) {
      hasError = true;
      errors.username = "Username is required";
    } else if (await usernameInUse(user!, usernameRef.current!.value)) {
      hasError = true;
      errors.username = "Username is already in use";
    }

    if (hasError) {
      setErrors(errors);
      return;
    }

    setIsLoading(true);

    const payload: UpdateUserPayload = {
      first_name: firstNameRef.current!.value,
      username: usernameRef.current!.value,
    };

    if (photoFile) {
      const photoUrls = await PhotoStorageService.storePhoto(photoFile!);
      payload.profile_photo_id = 0;
      payload.profile_photo_url = photoUrls.storageUrl;
      payload.profile_photo_thumb_url = photoUrls.thumbUrl;
    }

    await updateUser(payload);
    const newUser = await fetchUser();
    dispatch(setApiUser(newUser));

    if (photoFile) {
      setLoadingMessage("Processing photo...");
      await waitForPhotoProcessing(3000);
    }

    setIsLoading(false);
    setLoadingMessage("");

    navigate(-1);
  };

  return (
    <Layout header={"Edit Profile"} hideMenu>
      {isLoading && (
        <div className={"flex flex-col justify-center items-center h-full"}>
          <LoadingSpinner />
          {loadingMessage.trim().length > 0 && <div>{loadingMessage}</div>}
        </div>
      )}
      {!isLoading && (
        <div className={"p-2 flex flex-col shrink-0 overflow-y-scroll"}>
          <div className={"flex flex-row justify-start"}>
            <Button
              onClick={() => navigate(-1)}
              color={"none"}
              className={"p-0"}
            >
              <FaArrowLeftLong className={"mr-2"} />
              Back
            </Button>
          </div>

          <div className={"p-4"}>
            <div className="max-w-md mb-2">
              <div className="mb-2 block">
                <Label htmlFor="first_name" value="First Name" />
              </div>
              <TextInput
                id="first_name"
                required
                ref={firstNameRef}
                defaultValue={user?.first_name}
              />
              <ErrorMessage msg={errors.first_name} />
            </div>

            <div className="max-w-md mb-2">
              <div className="mb-2 block">
                <Label htmlFor="username" value="Username" />
              </div>
              <TextInput
                addon="@"
                id="username"
                placeholder='"cold_cheetos_69"'
                required
                ref={usernameRef}
                defaultValue={user?.username}
              />
              <ErrorMessage msg={errors.username} />
            </div>

            <div className="max-w-md" id="fileUpload">
              <div className="mb-2 block">
                <Label htmlFor="file" value="Change Your Profile Photo" />
              </div>
              <FileInput
                onChange={handleFileSelected}
                helperText="Leave this empty if you don't want to change your photo"
                id="file"
              />
            </div>

            <div className={"max-w-md mt-10"}>
              <Button className={"w-full"} color="dark" onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
