import { FaArrowLeftLong } from "react-icons/fa6";
import { Button } from "flowbite-react";

export default function BackButton({
  text,
  onClick,
}: {
  text?: string;
  onClick: () => void;
}) {
  text = text || "Back";
  return (
    <Button onClick={onClick} color={"none"} className={"p-0 text-primary"}>
      <FaArrowLeftLong className={"mr-2"} />
      {text}
    </Button>
  );
}
