import { GoDotFill } from "react-icons/go";
import classNames from "classnames";
import React from "react";

export const SwipeDots = ({
  index,
  total,
}: {
  index: number;
  total: number;
}) => {
  if (total === 1) {
    return null;
  }

  return (
    <div className={"flex flex-row mb-2"}>
      {Array.from(Array(total).keys()).map((i: number, idx) => {
        return (
          <GoDotFill
            key={i}
            className={classNames({
              "text-white": index === idx,
              "text-gray-500": index !== idx,
            })}
          />
        );
      })}
    </div>
  );
};
