import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { store } from "./state/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import AppInit from "./AppInit";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import inviteService from "./lib/services/invite-service";
import ReactGA from "react-ga4";
import logger from "./lib/utils/logger";
import { setInstallPrompt } from "./features/app-slice";
import { emit } from "./lib/utils/event-bus";

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_CODE_VERSION
          ? `${process.env.REACT_APP_CODE_VERSION}`
          : "1.0.0-dev",
      },
    },
  },
};

inviteService.storeInviteCode();

// @ts-ignore
window.INSTALL_PROMPT = null;
window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
  logger("beforeinstallprompt", e);
  // @ts-ignore
  window.INSTALL_PROMPT = e;
  logger("emitting install-prompt");
  emit("install-prompt", e);
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <HashRouter>
          <AppInit />
        </HashRouter>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
  // </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
