import { NewTwibLocationModel, TwibModel } from "../../lib/types";
import { Modal } from "flowbite-react";
import { useState } from "react";
import {
  setIsAddingTwib,
  setLocations,
  setTimeframeFilter,
} from "../../features/map/map-slice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import LocationStep from "./LocationStep";
import DetailsStep from "./DetailsStep";
import { setToast } from "../../features/config-slice";
import { matchedLocations } from "../../lib/api/locations";
import LoadingSpinner from "../LoadingSpinner";
import { emit } from "../../lib/utils/event-bus";
import { scopedLogger } from "../../lib/utils/logger";
import { timeframeFromTwib } from "../../lib/utils/date";
import { motion, AnimatePresence } from "framer-motion";
import AddTwibErrorBoundary from "../error-boundaries/add-twib-error-boundary";

const logger = scopedLogger("AddTwib");

export type Step = "location" | "details" | "people" | "submit";

export default function AddTwib() {
  const dispatch = useAppDispatch();
  const isAddingTwib = useAppSelector((state) => state.map.isAddingTwib);
  const [step, setStep] = useState<Step>("location");
  const [searchResult, setSearchResult] = useState<NewTwibLocationModel | null>(
    null
  );
  const [isFinishing, setIsFinishing] = useState<boolean>(false);
  const coords = useAppSelector((state) => state.map.coordinates);
  const [errBoundaryError, setErrBoundaryError] = useState<Error | null>(null);

  const handleTwibAdded = async (twib: TwibModel) => {
    setIsFinishing(true);

    const twibTimeFrame = timeframeFromTwib(twib);
    dispatch(setTimeframeFilter(twibTimeFrame));

    matchedLocations(coords.latitude, coords.longitude).then((locations) => {
      dispatch(setLocations(locations));

      dispatch(setIsAddingTwib(false));
      dispatch(setToast({ body: "Twib added!" }));

      logger("from AddTwib", twib);

      emit("move-map", {
        lat: twib.location.latitude,
        lng: twib.location.longitude,
      });
      setStep("location");
      setSearchResult(null);
      setIsFinishing(false);
    });
  };

  const modalTheme = {
    content: {
      inner: `relative rounded-lg ${
        step === "location" ? "bg-transparent" : "bg-primary-light"
      } shadow dark:bg-gray-700 flex flex-col max-h-[90vh]`,
    },
  };

  const handleClose = () => {
    setStep("location");
    dispatch(setIsAddingTwib(false));
  };

  return (
    <Modal
      style={{ zIndex: 1000 }}
      theme={modalTheme}
      position={"center"}
      size={"5xl"}
      show={isAddingTwib}
      onClose={handleClose}
      dismissible
    >
      <AddTwibErrorBoundary>
        {isFinishing && <LoadingSpinner />}
        <AnimatePresence>
          {!isFinishing && step === "location" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
              key={"location-step"}
            >
              <LocationStep
                setSearchResult={setSearchResult}
                onNext={() => setStep("details")}
                onClose={() => dispatch(setIsAddingTwib(false))}
              />
            </motion.div>
          )}

          {!isFinishing && step === "details" && searchResult !== null && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
              key={"details-step"}
              style={{ overflow: "auto" }}
            >
              <DetailsStep
                location={searchResult!}
                onTwibAdded={handleTwibAdded}
                handleClose={handleClose}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </AddTwibErrorBoundary>
    </Modal>
  );
}
