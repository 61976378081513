import LoadingSpinner from "./LoadingSpinner";
import SplashScreen from "./SplashScreen";

export default function SplashScreenLoading() {
  return (
    <SplashScreen>
      <div className="flex flex-col justify-center items-center h-full w-full">
        <LoadingSpinner />
      </div>
    </SplashScreen>
  );
}
