import { useRef, useState } from "react";
import {
  NewTwibAddressModel,
  NewTwibLocationModel,
  SearchResultItem,
} from "../lib/types";
import { useAppSelector } from "../state/hooks";
import { ListGroup, TextInput } from "flowbite-react";
import debounce from "lodash.debounce";
import { v4 } from "uuid";
import { address, autocomplete, details } from "../lib/api/foursquare";
import { locationTypes } from "../lib/dictionary";
import LoadingSpinner from "./LoadingSpinner";
import { BiSearchAlt } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";

export type SearchBarListItemProps = {
  searchResult: SearchResultItem;
  onLocationSelected: (location: SearchResultItem) => void;
};
const SearchBarListItem = ({
  searchResult,
  onLocationSelected,
}: SearchBarListItemProps) => {
  const lineOne =
    "name" in searchResult ? searchResult.name : searchResult.primary;
  const lineTwo =
    "address" in searchResult
      ? `${searchResult.address}, ${searchResult.city}, ${searchResult.region}`
      : searchResult.secondary;
  return (
    <div
      onClick={() => onLocationSelected(searchResult)}
      className={"flex flex-row justify-start items-center flex-grow"}
    >
      <div className={"flex flex-col items-start flex-grow"}>
        <div className="text-left">{lineOne}</div>
        <div style={{ fontSize: "0.6em" }}>{lineTwo}</div>
      </div>
    </div>
  );
};

export type MapSearchBarProps = {
  onLocationSelected: (searchResult: NewTwibLocationModel) => void;
  onClose: () => void;
};

export default function MapSearchBar({
  onLocationSelected,
  onClose,
}: MapSearchBarProps) {
  const userCoordinates = useAppSelector((state) => state.map.coordinates);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchResults, setSearchResults] = useState<SearchResultItem[] | null>(
    null
  );
  const [isFetchingDetails, setIsFetchingDetails] = useState<boolean>(false);
  const [sessionToken] = useState<string | null>(v4().replaceAll("-", ""));
  const [errorBoundaryError, setErrorBoundaryError] = useState<Error | null>(
    null
  );

  if (errorBoundaryError) {
    throw errorBoundaryError;
  }

  const handleLocationSelected = () => {
    return async (location: SearchResultItem) => {
      let hasErr = false;
      setIsFetchingDetails(true);
      try {
        if (location.location_type === locationTypes.address) {
          location = await address(
            (location as NewTwibAddressModel).fsq_addr_id
          );
        } else {
          location = await details(
            (location as NewTwibLocationModel).external_id,
            {},
            true
          );
        }
      } catch (error) {
        setErrorBoundaryError(error as Error);
        hasErr = true;
      }

      if (hasErr) {
        return;
      }

      if (searchInputRef.current) {
        searchInputRef.current.value = "";
      }

      setIsFetchingDetails(false);

      setSearchResults(null);
      onLocationSelected(location as NewTwibLocationModel);
    };
  };

  const searchLocations = async () => {
    const searchInput = searchInputRef.current;
    if (!searchInput || searchInput.value.trim().length === 0) {
      setSearchResults(null);
      return;
    }

    const searchValue = (searchInput as HTMLInputElement).value;

    const searchResults = await autocomplete({
      query: searchValue,
      ll: `${userCoordinates.latitude},${userCoordinates.longitude}`,
      session_token: sessionToken!,
      types: "place,address",
    });

    setSearchResults(searchResults);
  };

  const handleSearchLocations = debounce(searchLocations, 500);

  const Close = () => (
    <button
      onClick={onClose}
      className={
        "bg-white flex flex-row justify-center items-center rounded-full p-1 ml-2 w-14"
      }
    >
      <IoCloseOutline size={24} />
    </button>
  );

  return (
    <div className="mt-32 w-full flex flex-row justify-center items-center bg-transparent">
      {isFetchingDetails && <LoadingSpinner />}
      {!isFetchingDetails && (
        <div className={"flex flex-col w-full rounded-full bg-transparent"}>
          <div className={"flex flex-row"}>
            <input
              ref={searchInputRef}
              id="large"
              type="text"
              className={"rounded-full overflow-auto w-full text-lg flex-grow"}
              style={{
                height: 48,
                fontSize: "24px !important",
                paddingLeft: "20px",
              }}
              onInput={handleSearchLocations}
              placeholder="🔎  Search for a location"
            />
            <Close />
          </div>
          {searchResults !== null && (
            <div
              className={
                "max-h-[50vh] mt-1 overflow-y-scroll bg-transparent px-1"
              }
            >
              <ListGroup>
                {searchResults.map((searchResult, i) => {
                  return (
                    <ListGroup.Item key={`search-location-${i}`}>
                      <SearchBarListItem
                        searchResult={searchResult}
                        onLocationSelected={handleLocationSelected()}
                      />
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
