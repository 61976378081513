import { useState } from "react";
import classNames from "classnames";
import {
  BiChevronDown,
  BiRadioCircle,
  BiRadioCircleMarked,
} from "react-icons/bi";

export const DropdownButton = ({
  options,
  onSelectOption,
  selectedOption,
  disabled,
  outline,
}: {
  disabled?: boolean;
  options: string[];
  selectedOption: string;
  onSelectOption: (selected: string) => void;
  outline: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [selectedOption, setSelectedOption] = useState<string>(options[0]);
  const handleSelectOption = (option: string) => {
    if (disabled) {
      return () => {};
    }

    return (event: any) => {
      event.preventDefault();
      onSelectOption(option);
      setIsOpen(false);
    };
  };
  const handleOpenOption = (event: any) => {
    event.preventDefault();
    if (disabled) {
      return;
    }

    setIsOpen(!isOpen);
  };
  return (
    <div
      // style={{ minWidth: "125px" }}
      className={classNames("relative mx-1 pr-2 pl-3 py-1 rounded-full", {
        border: outline,
        "border-gray-300": !disabled,
        "border-gray-200": disabled,
      })}
    >
      <div
        className={classNames("flex flex-row items-center", {
          "justify-between": outline,
          "justify-start": !outline,
        })}
        onClick={handleOpenOption}
      >
        <span
          className={classNames("mr-1", {
            "text-gray-500": disabled,
          })}
        >
          {selectedOption}
        </span>
        <span className={""}>
          <BiChevronDown
            className={classNames({
              "text-gray-200": !outline,
              "text-gray-500": outline && !disabled,
              "text-gray-300": outline && disabled,
            })}
          />
        </span>
      </div>
      <div
        style={{ minWidth: "125px" }}
        className={classNames(
          "w-full left-0 absolute mt-2 bg-white shadow px-2 rounded text-gray-700 border border-gray-300",
          {
            hidden: !isOpen,
          }
        )}
      >
        {options.map((option, index) => {
          return (
            <div
              className={"flex flex-row justify-between items-center py-1"}
              onClick={handleSelectOption(option)}
              key={index}
            >
              {option}
              {option === selectedOption && (
                <div className={"ml-2"}>
                  <BiRadioCircleMarked />
                </div>
              )}
              {option !== selectedOption && (
                <div className={"ml-2"}>
                  <BiRadioCircle />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
DropdownButton.defaultProps = {
  disabled: false,
  outline: true,
};
