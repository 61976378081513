import { SubView } from "./SubView";
import { MessageThreadModel } from "../../lib/types";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../state/hooks";
import { fetchThreads } from "../../lib/api/message-threads";
import {
  isInFuture,
  twibDetailDate,
  twibDetailTime,
} from "../../lib/utils/date";
import { threadStatus } from "../../features/messages/message-threads-utils";
import { PhotoUrl } from "../../lib/utils/photo-url";
import { bgImage, icons } from "../../config/theme";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import SubViewTabMenu from "./SubViewTabMenu";
import { useParams } from "react-router";
import { emit } from "../../lib/utils/event-bus";
import { SubViewHeader } from "./SubViewHeader";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { IoArrowForward } from "react-icons/io5";
import { MdArrowForward, MdArrowForwardIos } from "react-icons/md";

const ThreadDetails = ({ thread }: { thread: MessageThreadModel }) => {
  const navigate = useNavigate();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const handleShowRsvps = (show: boolean) => {
    setShowInfo(show);
  };

  const imgUrl = thread.messages.length
    ? PhotoUrl.user(thread.messages[thread.messages.length - 1].user).thumb
    : null;
  const imageOrText = imgUrl ? (
    <img
      src={imgUrl}
      className={"rounded-full w-10 h-10 mr-2 my-1 border border-lime-300"}
    />
  ) : (
    <div className="my-3 ml-1" style={{ color: "grey", fontSize: 12 }}>
      No Messages Yet
    </div>
  );

  const text = thread.messages.length
    ? thread.messages[thread.messages.length - 1].text
    : "";

  let time = "";
  if (text.length) {
    // convert created_at to hours ago with 'h' or 2d if more than 24 hours
    // and normalize timezone of created_at to current timezone
    const date = new Date(
      thread.messages[thread.messages.length - 1].created_at
    );
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const hours = Math.round(diff / (1000 * 60 * 60));
    if (hours < 24) {
      time = `${Math.abs(hours)}h`;
    } else {
      const days = Math.round(hours / 24);
      time = `${Math.abs(days)}d`;
    }
  }

  const { twib } = thread;

  const handleClick = () => {
    navigate(
      generatePath(routes.map, { view: "messages", id: thread.id.toString() })
    );
  };

  const coverImage: string | null = twib?.image_uri || null;

  return (
    <div className={"rounded-2xl mb-4"}>
      <div
        onClick={handleClick}
        className={
          "rounded-t-2xl bg-primary-light p-2 flex flex-row justify-between items-center"
        }
      >
        <div className="flex flex-row">
          {imageOrText}
          {!!text.length && (
            <div className="flex flex-col">
              <div
                className="mt-1 ml-1 pr-6 text-gray-500"
                style={{ fontSize: 14 }}
              >
                {thread.messages[thread.messages.length - 1].user.first_name}
              </div>
              <div className="ml-1 pr-6" style={{ fontSize: 12 }}>
                {text}
              </div>
            </div>
          )}
        </div>
        {imgUrl ? (
          <div className="mt-1 ml-1 pr-2 text-gray-400">{time}</div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "fit-content",
              height: "100%",
              padding: "6px 8px",
              // margin: "0px 10px 10px 10px",
              background: "#AFBBF2",
              border: "1px solid #AFBBF2",
              borderRadius: "24px",
              justifyContent: "center",
              color: "#262626",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            Start Chat
          </div>
        )}
      </div>
      <div
        onClick={() => setShowInfo(!showInfo)}
        className={
          "rounded-b-2xl bg-gradient-primary p-2 text-gray-200 flex flex-col"
        }
      >
        <div className="flex flex-row justify-between items-center">
          <div
            className={"text-white ml-1"}
            style={{ fontSize: 15, maxWidth: "50%" }}
          >
            {thread.twib.location.name}
          </div>
          <div className={"flex flex-row justify-between items-center text-sm"}>
            <div
              className={"flex flex-row justify-start items-center mr-2"}
              style={{
                minWidth: "fit-content",
              }}
            >
              🗓&nbsp;&nbsp;
              {twibDetailDate(twib)}
            </div>
            &nbsp; &nbsp;
            <div
              style={{
                minWidth: "fit-content",
              }}
              className={"flex flex-row justify-start items-center"}
            >
              ⏰&nbsp;&nbsp;
              {twibDetailTime(twib)}
              &nbsp;
            </div>
            {(coverImage || thread.twib.description) && (
              <>
                {!showInfo ? (
                  <BiChevronDown fontSize={18} />
                ) : (
                  <BiChevronUp fontSize={18} />
                )}
              </>
            )}
          </div>
        </div>
        {showInfo && (
          <div className="flex flex-col">
            {twib.description && (
              <div
                style={{ padding: "12px 8px" }}
                className={"flex flex-row justify-around rounded-l mt-1"}
              >
                {twib.description}
              </div>
            )}

            {coverImage && (
              <img
                style={{
                  height: "fit-content",
                }}
                className={"w-full object-cover rounded-xl mt-2"}
                src={coverImage}
                alt={coverImage}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default function ThreadListSubView() {
  const navigate = useNavigate();
  const appUser = useAppSelector((state) => state.user.user);
  const [threads, setThreads] = useState<MessageThreadModel[]>([]);
  const [hasFetchedThreads, setHasFetchedThreads] = useState<boolean>(false);
  const { id: threadType = "active" } = useParams();

  useEffect(() => {
    if (appUser !== null && !hasFetchedThreads) {
      fetchThreads().then((threads) => {
        setThreads(threads);
        setHasFetchedThreads(true);
      });
    }
  }, [appUser, hasFetchedThreads]);

  const pendingThreads = threads.filter(
    (thread) =>
      isInFuture(thread) &&
      (threadStatus(thread) === "pending" ||
        threadStatus(thread) === "declined")
  );
  pendingThreads.sort(
    (threadA: MessageThreadModel, threadB: MessageThreadModel) => {
      if (threadA.twib.twib_date !== null && threadB.twib.twib_date === null) {
        return 1;
      }
      if (threadA.twib.twib_date === null && threadB.twib.twib_date !== null) {
        return -1;
      }
      if (threadA.twib.twib_date !== null && threadB.twib.twib_date !== null) {
        return threadA.twib.twib_date > threadB.twib.twib_date ? 1 : -1;
      }

      return threadA.twib.location.name.localeCompare(
        threadB.twib.location.name
      );
    }
  );
  const activeThreads = threads.filter(
    (thread) => isInFuture(thread) && threadStatus(thread) === "active"
  );
  const pastThreads = threads.filter((thread) => !isInFuture(thread));
  pastThreads.sort(
    (threadA: MessageThreadModel, threadB: MessageThreadModel) => {
      if (threadA.twib.twib_date !== null && threadB.twib.twib_date === null) {
        return 1;
      }
      if (threadA.twib.twib_date === null && threadB.twib.twib_date !== null) {
        return -1;
      }
      if (threadA.twib.twib_date !== null && threadB.twib.twib_date !== null) {
        return threadA.twib.twib_date > threadB.twib.twib_date ? 1 : -1;
      }

      return threadA.twib.location.name.localeCompare(
        threadB.twib.location.name
      );
    }
  );

  // const handleSetThreadType =
  //   (view: "active" | "invites" | "archive") => () => {
  //     setThreadType(view);
  //   };

  const ButtonText = ({ text }: { text: string }) => (
    <span className={""}>{text}</span>
  );

  let threadsToDisplay: MessageThreadModel[] = [];
  switch (threadType) {
    case "active":
      threadsToDisplay = activeThreads;
      break;
    case "invites":
      threadsToDisplay = pendingThreads;
      break;
    case "archive":
      threadsToDisplay = pastThreads;
      break;
  }

  const menuItems = [
    {
      text: "Active",
      count: activeThreads.length,
      active: threadType === "active",
      href: generatePath(routes.map, { view: "messages", id: "active" }),
      emptyCopy: "Your Twibs will show up here",
    },
    {
      text: "Invites",
      count: pendingThreads.length,
      active: threadType === "invites",
      href: generatePath(routes.map, { view: "messages", id: "invites" }),
      emptyCopy: "Invitations will show up here",
    },
    {
      text: "Past",
      count: pastThreads.length,
      active: threadType === "archive",
      href: generatePath(routes.map, { view: "messages", id: "archive" }),
      emptyCopy: "Past twibs will show up here",
    },
  ];

  return (
    <SubView onClose={() => navigate(routes.mapRoot)}>
      <SubViewHeader title="My Twibs" height={112}>
        <div style={{ height: 16 }} />
        <SubViewTabMenu items={menuItems} />
        <div style={{ height: 12 }} />
        <div
          className={"overflow-y-scroll"}
          style={{ maxHeight: "calc(100vh - 128px)" }}
        >
          {threadsToDisplay.map((thread) => (
            <ThreadDetails key={`thread-${thread.id}`} thread={thread} />
          ))}
          {threadsToDisplay.length === 0 && (
            <div
              className={
                "text-center bg-primary-light rounded-lg py-4 text-primary mt-2"
              }
            >
              {menuItems.find((m) => !!m.active)?.emptyCopy}
            </div>
          )}
        </div>
      </SubViewHeader>
    </SubView>
  );
}
