import { useParams } from "react-router";
import ThreadListSubView from "./ThreadListSubView";
import MessageThreadSubView from "./MessageThreadSubView";

export default function MessagesSubView() {
  const { id } = useParams();

  if (
    typeof id === "undefined" ||
    ["active", "invites", "archive"].includes(id)
  ) {
    return <ThreadListSubView />;
  } else {
    return <MessageThreadSubView />;
  }
}
