import { MessageModel } from "../../../lib/types";
import { formattedDate } from "../../../lib/utils/date";

export const MessageDate = ({ message }: { message: MessageModel }) => {
  return (
    <div className={"font-light text-gray-500 text-xs"}>
      {formattedDate(message.created_at)}
    </div>
  );
};
