import { Spinner } from "flowbite-react";

export default function LoadingSpinner({
  size,
}: {
  size: "sm" | "md" | "lg" | "xl";
}) {
  return <Spinner color="gray" size={size} />;
}

LoadingSpinner.defaultProps = {
  size: "md",
};
