import {
  ChatUserModel,
  MessageModel,
  MessageThreadModel,
  SystemMessageModel,
  UserModel,
} from "../../../lib/types";
import { normalize } from "../../../lib/utils/date";
import { Message } from "./Message";
import { chatUserStatus } from "../../../lib/dictionary";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

const sortMessages = (
  a: MessageModel | SystemMessageModel,
  b: MessageModel | SystemMessageModel
) => {
  const dateA = normalize(a.created_at);
  const dateB = normalize(b.created_at);
  return dateA >= dateB ? -1 : 1;
};

const SystemMessage = ({ message }: { message: SystemMessageModel }) => {
  const date = normalize(message.created_at);
  return (
    <div className={"flex flex-row items-center justify-center mb-2"}>
      <div className={"text-primary italic text-xs mr-2"}>{message.text}</div>
      <div className={"text-xs italic font-light "}>
        {timeAgo.format(date.toJSDate())}
      </div>
    </div>
  );
};

const randomId = (): string => {
  return +new Date() + Math.random().toString(36).substring(2, 15);
};

export const MessagesList = ({
  user,
  thread,
}: {
  user: UserModel;
  thread: MessageThreadModel;
}) => {
  // insert system messages, then sort

  const messages: (MessageModel | SystemMessageModel)[] = [...thread.messages];

  // add "twib created at" system message
  messages.push({
    id: randomId(),
    type: "system",
    text: `${
      thread.twib.user_id === user.id ? "You" : thread.twib.user.first_name
    } created this TWIB`,
    created_at: thread.created_at,
  });

  const attendingUsers = thread.users.filter(
    (user: ChatUserModel) =>
      user.pivot.status === chatUserStatus.accepted &&
      user.id !== thread.twib.user_id
  );
  for (const attendingUser of attendingUsers) {
    messages.push({
      id: randomId(),
      type: "system",
      text: `${attendingUser.first_name} joined!`,
      created_at: attendingUser.pivot.updated_at,
    });
  }

  messages.sort(sortMessages);

  return (
    <div
      className={"px-4 mb-2 text-primary"}
      style={{
        flexDirection: "column-reverse",
        overflow: "auto",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {messages.map((message) => {
        if ("type" in message && message.type === "system") {
          return (
            <SystemMessage
              key={`system-message-${message.id}`}
              message={message}
            />
          );
        }
        return (
          <Message
            key={`message-${message.id}`}
            user={user!}
            message={message as MessageModel}
          />
        );
      })}
    </div>
  );
};
