import { useEffect, useRef, useState } from "react";
import { UserModel } from "../../../lib/types";
import { fetchSuggestions, search } from "../../../lib/api/friends";
import { Button, TextInput } from "flowbite-react";
import SearchResultUser from "../../../features/user/SearchResultUser";
import debounce from "lodash.debounce";

export const SearchTab = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchedUsers, setSearchedUsers] = useState<UserModel[] | null>(null);
  const [suggestions, setSuggestions] = useState<UserModel[]>([]);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  useEffect(() => {
    fetchSuggestions().then(setSuggestions);
  }, []);

  const handleSearch = async () => {
    const searchInput = searchInputRef.current;
    if (!searchInput || searchInput.value.trim().length === 0) {
      setSearchedUsers(null);
      return;
    }

    const query = searchInputRef.current!.value.trim();
    const users = await search(query);
    setSearchedUsers(users);
    setHasSearched(true);
  };

  const handleSearchUsers = debounce(handleSearch, 500);

  return (
    <div className={"p-4 bg-primary-light rounded-xl mt-2"}>
      <h3 className={"mb-2 font-bold text-gray-700"}>Find friends:</h3>
      <div
        className={"w-full flex flex-row"}
        style={{ border: "1px solid grey", borderRadius: 50 }}
      >
        <input
          ref={searchInputRef}
          id="large"
          type="text"
          className={"rounded-full overflow-auto w-full text-lg flex-grow"}
          style={{
            height: 48,
            fontSize: "24px !important",
          }}
          onInput={handleSearchUsers}
          placeholder=" Search by username"
        />
        {/* <Button
          pill
          className={"bg-gradient-button-light"}
          onClick={handleSearch}
        >
          Search
        </Button> */}
      </div>
      {Array.isArray(searchedUsers) && (
        <div className={"mt-4"}>
          {searchedUsers.length === 0 && <div>No users found</div>}
          {searchedUsers.length > 0 &&
            searchedUsers.map((user) => {
              return (
                <div className="mb-4" key={`search-${user.id}`}>
                  <SearchResultUser user={user} />
                </div>
              );
            })}
        </div>
      )}
      {!hasSearched && suggestions.length > 0 && (
        <div className={"mt-6"}>
          <h3 className={"mb-2 font-bold text-gray-700 font-light"}>
            People you might know:
          </h3>
          {suggestions.map((user) => {
            return (
              <div className="mb-4" key={`suggestion-${user.id}`}>
                <SearchResultUser user={user} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
