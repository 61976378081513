import { PropsWithChildren } from "react";
import classNames from "classnames";

export type CircleButtonProps = {
  onClick?: () => void;
  active: boolean;
} & PropsWithChildren<unknown>;
export default function CircleButton({
  onClick,
  active,
  children,
}: CircleButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        "w-14 h-14 rounded-full border-2 border-gray-200 flex justify-center items-center bg-primary-light",
        { "border-orange-500": active }
      )}
    >
      {children}
    </button>
  );
}

CircleButton.defaultProps = {
  onClick: () => {},
  active: false,
};
