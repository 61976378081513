import React from "react";
import {
  LocationModel,
  SlimLocationModel,
  TwibModel,
  UserModel,
} from "../lib/types";
import { PhotoUrl } from "../lib/utils/photo-url";
import classNames from "classnames";

export const imageSource = (location: SlimLocationModel): string => {
  let src = PhotoUrl.location(location);

  if (location.twibs && location.twibs.length === 1) {
    src = PhotoUrl.twib({
      image_uri: location.twibs[0].image_uri,
      location: location as LocationModel,
    } as TwibModel);
  }

  return src;
};

export default function LocationMarker({
  appUser,
  location,
}: {
  appUser: UserModel;
  location: SlimLocationModel | LocationModel;
}) {
  const userHasTwibAtLocation = location.twibs?.some(
    (twib: TwibModel) => twib.user_id === appUser.id
  );

  return (
    <img
      src={imageSource(location)}
      alt={"location"}
      className={classNames(
        "mr-4 rounded-full shadow-amber-300 border-2 object-cover w-8 h-8 object-center",
        {
          "border-amber-300": !userHasTwibAtLocation,
          "border-blue-900": userHasTwibAtLocation,
        }
      )}
    />
  );
}
