import { MessageModel, UserModel } from "../../../lib/types";
import { AppUserMessage } from "./OwnMessage";
import { MessageDate } from "./MessageDate";
import { PhotoUrl } from "../../../lib/utils/photo-url";
import { generatePath, useNavigate } from "react-router";
import { routes } from "../../../config/routes";
import classNames from "classnames";

export const Message = ({
  user,
  message,
}: {
  user: UserModel;
  message: MessageModel;
}) => {
  const navigate = useNavigate();
  if (user.id === message.user_id) {
    return <AppUserMessage message={message} />;
  }

  const handleUserClicked = () => {
    navigate(generatePath(routes.map, { view: "user", id: user.id }));
  };

  return (
    <div className={"flex flex-col justify-start items-start pb-2 pt-2"}>
      <div className={"flex flex-row justify-start items-center mb-1"}>
        <img
          onClick={handleUserClicked}
          height={36}
          width={36}
          className={"rounded mr-2 rounded-full"}
          src={PhotoUrl.user(message.user).thumb}
        />
        <div className={"flex flex-col justify-around items-start"}>
          <div onClick={handleUserClicked}>@{message.user.username}</div>
          <MessageDate message={message} />
        </div>
      </div>
      <div className={"text-primary"}>{message.text}</div>
    </div>
  );
};
