import { UserModel } from "../../lib/types";
import classNames from "classnames";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { Button } from "flowbite-react";
import { PhotoUrl } from "../../lib/utils/photo-url";

type FriendsListProps = {
  list: UserModel[];
  selected: UserModel[];
  onSelect: (user: UserModel) => void;
  onDeselectUser: (user: UserModel) => void;
  onSubmit: () => void;
};
export const FriendList = ({
  list,
  selected,
  onSelect,
  onDeselectUser,
  onSubmit,
}: FriendsListProps) => {
  list.sort((a: UserModel, b: UserModel) => {
    return a.first_name.localeCompare(b.first_name);
  });
  return (
    <div className={"flex flex-col items-center"}>
      {list.map((user, index) => {
        const isSelected = selected.find((item) => item.id === user.id);
        return (
          <div
            key={`user-select-${user.id}`}
            className={classNames("flex flex-row justify-between w-full py-4", {
              "border-b": index !== list.length - 1,
            })}
            onClick={() => (isSelected ? onDeselectUser(user) : onSelect(user))}
          >
            <div className={"flex flex-row items-center"}>
              <img
                src={PhotoUrl.user(user).thumb}
                className={"rounded-full w-12 h-12 mr-2"}
              />
              <div>
                {user.first_name} ({user.username})
              </div>
            </div>
            <div className={"flex flex-row items-center"}>
              <div className={"text-xl"}>
                {isSelected ? <AiFillCheckCircle /> : <AiOutlineCheckCircle />}
              </div>
            </div>
          </div>
        );
      })}
      {selected.length > 0 && (
        <div className={"mt-4 w-full"}>
          <Button className={"w-full"} onClick={onSubmit}>
            Select {selected.length} friend{selected.length > 1 && "s"}
          </Button>
        </div>
      )}
    </div>
  );
};
