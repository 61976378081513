import { useEffect, useState } from "react";
import { Button } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { canInstall, isAppInstalled } from "../lib/utils/pwa";
import LoadingSpinner from "../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { routes } from "../config/routes";
import { MdInstallMobile } from "react-icons/md";
import { setIsInstalled } from "../features/app-slice";
import logger from "../lib/utils/logger";

const InstallPrompt = ({
  prompt,
  isInstalled,
}: {
  prompt: any | null;
  isInstalled: boolean;
}) => {
  const [isPrompting, setIsPrompting] = useState<boolean>(false);

  const handleClick = async () => {
    const result = await prompt.prompt();
    logger("install prompt result", result);
  };

  return (
    <div className={"flex flex-col items-center justify-end"}>
      {isInstalled && <p>App installed successfully!</p>}
      {!isInstalled && (
        <>
          <Button
            className={"bg-secondary text-rose-600"}
            onClick={handleClick}
          >
            <span className={"mr-2"}>Install App</span> <MdInstallMobile />
          </Button>
          <p className={"text-sm text-gray-700 mt-4"}>
            This web app can be installed, just like an app from an app store,
            and provides a better experience
          </p>
        </>
      )}
    </div>
  );
};

const InstallStep = ({
  appIsInstalled,
  onNext,
}: {
  appIsInstalled: boolean;
  onNext: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const installPrompt = useAppSelector((state) => state.app.installPrompt); // [prompt, setPrompt

  useEffect(() => {
    if (!appIsInstalled) {
      window.addEventListener("appinstalled", () => {
        dispatch(setIsInstalled(true));
        navigate(routes.mapRoot);
      });
    }
  }, [appIsInstalled]);

  if (installPrompt === null) {
    return (
      <div className={"mx-14 flex flex-col items-center text-center"}>
        InstallStep: waiting for install prompt
        <LoadingSpinner />
      </div>
    );
  }

  if (!appIsInstalled && installPrompt !== "installed") {
    return (
      <div
        className={
          "mx-14 flex flex-col items-center text-center justify-center h-full"
        }
      >
        <InstallPrompt prompt={installPrompt} isInstalled={appIsInstalled} />
      </div>
    );
  }

  return (
    <div>
      <button onClick={onNext}>Next</button>
    </div>
  );
};

export default function Install() {
  const navigate = useNavigate();
  const installPrompt = useAppSelector((state) => state.app.installPrompt);
  const isInstalled = useAppSelector(
    (state) => state.app.isInstalled
  ) as boolean; // by this point this has to be set
  const [isInstallable, setIsInstallable] = useState<boolean | null>(null); // [installable, setInstallable

  useEffect(() => {
    if (isInstalled) {
      navigate(routes.mapRoot);
      return;
    }

    canInstall().then((installable: boolean) =>
      setIsInstallable(installable && installPrompt !== null)
    );
  }, []);

  return (
    <div className={"h-full flex flex-column justify-center items-center"}>
      {isInstalled === null && (
        <div>
          <div>isInstalled is null</div>
          <LoadingSpinner />
        </div>
      )}
      {isInstalled === false && !isInstallable && (
        <div className={"flex flex-col justify-center items-center"}>
          <div>Sorry, installing not available on your device</div>
          <div className={"text-xs"}>
            (if you're seeing this, i'm still trying to figure this out)
          </div>
          <Button
            className={"bg-primary mt-4"}
            onClick={() => navigate(routes.mapRoot)}
          >
            Back
          </Button>
        </div>
      )}
      {!isInstalled && isInstallable && (
        <InstallStep
          appIsInstalled={isInstalled as boolean}
          onNext={() => navigate(routes.mapRoot)}
        />
      )}
      {isInstalled && (
        <div className={"flex flex-col justify-center items-center"}>
          <div>Already installed!</div>
          <Button
            className={"bg-primary mt-4"}
            onClick={() => navigate(routes.mapRoot)}
          >
            Back
          </Button>
        </div>
      )}
    </div>
  );
}
