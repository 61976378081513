import { Component, PropsWithChildren } from "react";
import logger from "../../lib/utils/logger";
import Rollbar from "rollbar";
import { Context, getRollbarFromContext } from "@rollbar/react";
import { Button } from "flowbite-react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useAppDispatch } from "../../state/hooks";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { setIsAddingTwib } from "../../features/map/map-slice";

const WithRouter = (Wrapped: any) => {
  const Wrapper = (props: any) => {
    const navigate = useNavigate();

    return <Wrapped {...props} navigate={navigate} />;
  };
  return Wrapper;
};

type WithDispatchProps = {
  dispatch: ThunkDispatch<any, any, any>;
};
const WithDispatch = (Wrapped: any) => {
  const Wrapper = (props: any) => {
    const dispatch = useAppDispatch();

    return <Wrapped {...props} dispatch={dispatch} />;
  };
  return Wrapper;
};

type AddTwibErrorBoundaryProps = {
  navigate: NavigateFunction;
} & PropsWithChildren<{}> &
  WithDispatchProps;

export default WithDispatch(
  WithRouter(
    class AddTwibErrorBoundary extends Component<
      AddTwibErrorBoundaryProps,
      { hasError: boolean }
    > {
      static contextType = Context;
      rollbar: Rollbar | undefined;

      constructor(props: AddTwibErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
      }

      componentDidMount() {
        this.rollbar = getRollbarFromContext(this.context as typeof Context);
      }

      static getDerivedStateFromError() {
        return { hasError: true };
      }
      componentDidCatch(error: any, errorInfo: any) {
        const rollbar = getRollbarFromContext(this.context as typeof Context);
        rollbar.error("Add Twib Error Boundary", error, errorInfo);
        logger("error", error, errorInfo);
      }
      render() {
        if (this.state.hasError) {
          return (
            <div
              className={
                "flex flex-col items-center bg-gradient-permissions text-gray-200 p-4 rounded"
              }
            >
              <h1 className={"font-bold text-2xl"}>Uh-oh!</h1>
              <p className={"text-gray-300"}>
                Something went wrong. Please try again.
              </p>
              <div className={"mt-4"}>
                <Button
                  onClick={() => this.props.dispatch(setIsAddingTwib(false))}
                >
                  Close
                </Button>
              </div>
            </div>
          );
        }

        return this.props.children;
      }
    }
  )
);
