import {
  iconForVisibilityType,
  paymentTypeDetails,
  visibilityByType,
} from "../../lib/dictionary";
import { bgImage, icons } from "../../config/theme";
import React, { useCallback, useEffect, useState } from "react";
import { SubView, SubViewProps } from "./SubView";
import { LocationModel, TwibModel, UserModel } from "../../lib/types";
import LoadingSpinner from "../../components/LoadingSpinner";
import { twibDetailDate, twibDetailTime } from "../../lib/utils/date";
import {
  fetchLocationWithTwibs,
  matchedLocations,
} from "../../lib/api/locations";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { submitRSVP } from "../../lib/api/message-threads";
import { useParams } from "react-router";
import { disableTwib, fetchTwibById } from "../../lib/api/twibs";
import { SwipeDots } from "../../components/SwipeDots";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { filterAcceptedUsers } from "../../lib/services/chat-users";
import { TwibUsersStack } from "../../components/TwibUsersStack";
import { emit } from "../../lib/utils/event-bus";
import { setLocations } from "../../features/map/map-slice";
import { scopedLogger } from "../../lib/utils/logger";
import { IoClose } from "react-icons/io5";
import { Button } from "flowbite-react";
import { Tooltip } from "react-tooltip";
import { BsTrash } from "react-icons/bs";
import { setDialogMessage, setTwibToDelete } from "../../features/config-slice";
import { PhotoUrl } from "../../lib/utils/photo-url";

const logger = scopedLogger("LocationTwibDetailsSubView");

export type TwibDetailsSubViewProps = SubViewProps & {};
export const LocationTwibDetailsSubView = ({
  onClose,
}: TwibDetailsSubViewProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const appUser = useAppSelector((state) => state.user.user);
  const { view, id } = useParams();
  const maxAvatarStack: number = 3;
  const [location, setLocation] = useState<LocationModel | null>(null);
  const [twibIndex, setTwibIndex] = useState<number | null>(null);
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const coords = useAppSelector((state) => state.map.coordinates);
  const dialogResponse = useAppSelector((state) => state.config.dialogResponse);
  const twibToDelete = useAppSelector((state) => state.config.twibToDelete);

  const handleOnClose = () => {
    emit(`close-marker`, {});
    if (typeof onClose === "function") {
      onClose();
    }
  };

  useEffect(() => {
    if (
      typeof view !== "undefined" &&
      ["location", "twib"].includes(view) &&
      typeof id !== "undefined"
    ) {
      switch (view) {
        case "location":
          logger("LOCATION: fetching location with twibs");
          if (location !== null) {
            logger("never mind, already have location");
            break;
          }
          fetchLocationWithTwibs(parseInt(id!, 10)).then((location) => {
            logger("fetched location, setting location");
            setLocation(location);
            setTwibIndex(0);
          });
          break;
        case "twib":
          if (location !== null && twibIndex !== null) {
            break;
          }
          fetchTwibById(parseInt(id!, 10))
            .then((twib) => {
              return fetchLocationWithTwibs(twib.location_id);
            })
            .then((apiLocation) => {
              let twibIndex = apiLocation.twibs.findIndex(
                (twib) => twib.id === parseInt(id!, 10)
              );
              setLocation(apiLocation);
              setTwibIndex(twibIndex === -1 ? 0 : twibIndex);
            });
          break;
      }
    }
  }, [view, id]);

  const handleRsvp = (rsvp: "accept" | "decline") => {
    return async () => {
      // remove the twib instead
      if (rsvp === "decline" && twib!.user.id === appUser!.id) {
        disableTwib(twib!)
          .then(() => {
            emit(`close-marker`, {});
          })
          .then(() => matchedLocations(coords.latitude, coords.longitude))
          .then((locations: LocationModel[]) => {
            dispatch(setLocations(locations));
          })
          .then(() => {
            navigate(routes.mapRoot);
          });

        return;
      }

      submitRSVP(twib!.chat, rsvp).then((res) => {
        if (rsvp === "accept") {
          handleShowMessages();
        }
      });
    };
  };

  const handleShowMessages = () => {
    navigate(generatePath(routes.map, { view: "messages", id: twib?.chat.id }));
  };

  const handleOnSwipe = (direction: -1 | 1) => {
    if (location === null) {
      return;
    }

    if (twibIndex === null) {
      setTwibIndex(0);
      return;
    }

    const newIndex: number = twibIndex + direction;

    if (newIndex < 0 || newIndex >= location.twibs.length) {
      return;
    }

    setTwibIndex(newIndex);
  };

  function handleTouchStart(e: any) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e: any) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 75) {
      // do your stuff here for left swipe
      handleOnSwipe(1);
    }

    if (touchStart - touchEnd < -75) {
      // do your stuff here for right swipe
      handleOnSwipe(-1);
    }
  }

  const twib: TwibModel | null =
    twibIndex !== null ? location?.twibs[twibIndex] || null : null;
  const coverImage: string | null = twib?.image_uri || null;
  const userHasRsvpd =
    (twib !== null &&
      twib.chat.users
        .filter(filterAcceptedUsers)
        .filter((u: UserModel) => u.id === appUser?.id).length > 0) ||
    false;
  const twibRepliesCount =
    twib !== null
      ? twib.chat.users
          .filter(filterAcceptedUsers)
          .filter((u) => u.id !== twib.user_id)
          .filter((u) => u.id !== appUser?.id).length
      : 0;

  const handleUserClicked = () => {
    navigate(generatePath(routes.map, { view: "user", id: twib?.user.id }));
  };

  const isMe = appUser?.id === twib?.user.id;
  const handleTrash = useCallback(() => {
    const message = `Are you sure you want to delete your Twib at ${location?.name}? This will delete the chat for all attendees`;
    dispatch(setDialogMessage(message));
    dispatch(setTwibToDelete(twib!));
  }, [twib]);

  useEffect(() => {
    console.log({ twib, dialogResponse, twibToDelete });
    if (dialogResponse && twib?.id === twibToDelete?.id) {
      disableTwib(twib!).then(() => {
        navigate(generatePath(routes.map, { view: "refresh" }));
      });
    }
  }, [dialogResponse]);

  const [fullscreen, setFullscreen] = useState<boolean>(false);

  return (
    <SubView dark={false}>
      <div
        style={{
          margin: `${fullscreen ? "5%" : "25%"} 10px`,
          maxHeight: "calc(100vh - 50px)",
          overflow: "scroll",
        }}
        className={"bg-primary p-2 flex flex-col rounded-3xl"}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <Button
          size={"xxs"}
          color={"white"}
          onClick={handleOnClose}
          style={{
            alignSelf: "end",
          }}
        >
          <IoClose fontSize={24} color="white" />
        </Button>
        {location === null && (
          <div style={{ textAlign: "center", margin: 20 }}>
            <LoadingSpinner />
          </div>
        )}
        {location !== null && twib === null && (
          <div style={{ textAlign: "center", margin: 20 }}>
            ⚠ There was an error loading this Twib ⚠
          </div>
        )}
        {location !== null && twib !== null && (
          <>
            <div
              className={"text-white text-sm"}
              style={{
                textAlign: "center",
              }}
            >
              Twibs at:
            </div>
            <div
              className={"text-white text-lg"}
              style={{
                textAlign: "center",
              }}
            >
              {location.name}
            </div>
            <div className={"flex-grow bg-primary-light rounded-2xl mt-4"}>
              {coverImage && (
                <img
                  onClick={() => setFullscreen(!fullscreen)}
                  style={{
                    height: fullscreen ? "fit-content" : "100px",
                  }}
                  className={"w-full object-cover"}
                  src={coverImage}
                  alt={coverImage}
                />
              )}

              <div className={"p-3"}>
                <div className={"flex flex-row justify-between font-bold mb-3"}>
                  <div
                    className={"flex flex-row items-center"}
                    style={{
                      flexWrap: "wrap",
                      maxWidth: "calc(60%)",
                    }}
                  >
                    <img
                      onClick={handleUserClicked}
                      src={PhotoUrl.user(twib.user).thumb}
                      alt="profile avatar"
                      className={
                        "rounded-full w-10 h-10 border border-lime-300"
                      }
                    />
                    <span
                      style={{
                        fontSize: 14,
                      }}
                      className="ml-2"
                    >
                      {twib.user.first_name}
                    </span>
                    <span
                      style={{
                        fontSize: 12,
                        color: "grey",
                      }}
                      className="ml-2"
                    >
                      @{twib.user.username}
                    </span>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <div
                        data-tooltip-id="vistype"
                        data-tooltip-content={visibilityByType(
                          twib.visibility_type
                        )}
                      >
                        {iconForVisibilityType(twib.visibility_type)}
                      </div>
                      <Tooltip place="bottom" id="vistype" />
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                    }}
                    className={"flex flex-row justify-start items-center"}
                  >
                    <span className={"mr-2"}>
                      {paymentTypeDetails[twib.type.name].icon}
                    </span>
                    {twib.type.name}
                  </div>
                </div>

                {twib.description && (
                  <div
                    style={{ background: "#2626260D", padding: "12px 8px" }}
                    className={"flex flex-row justify-around"}
                  >
                    {twib.description}
                  </div>
                )}

                <div
                  style={{ background: "#2626260D", padding: "12px 8px" }}
                  className={"flex flex-row justify-around items-center mt-2"}
                >
                  <div
                    className={"flex flex-row justify-start items-center mr-2"}
                  >
                    🗓&nbsp;&nbsp;
                    {twibDetailDate(twib)}
                  </div>
                  <div
                    style={{
                      height: "100%",
                      padding: "10px 0px",
                      width: "1px",
                      background: "#2626264D",
                    }}
                  />
                  <div className={"flex flex-row justify-start items-center"}>
                    ⏰&nbsp;&nbsp;
                    {twibDetailTime(twib)}
                    &nbsp;
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {isMe && (
                  <div
                    style={{
                      display: "flex",
                      width: "50px",
                      height: "100%",
                      padding: "10px 40px",
                      margin: "0px 0px 0px 10px",
                      background: "transparent",
                      borderRadius: "24px",
                      boxSizing: "border-box",
                      border: "1px solid lightgrey",
                      fontSize: "16px",
                      fontWeight: 500,
                      justifyContent: "center",
                    }}
                    onClick={handleTrash}
                  >
                    <button>
                      <BsTrash color="gray" />
                    </button>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    padding: "6px 8px",
                    margin: "0px 10px 10px 8px",
                    background: "#AFBBF2",
                    border: "1px solid #AFBBF2",
                    borderRadius: "24px",
                    justifyContent: "center",
                    color: "#262626",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                  onClick={
                    userHasRsvpd ? handleShowMessages : handleRsvp("accept")
                  }
                >
                  <button>{userHasRsvpd ? "View Chat" : "Join"}</button>
                </div>
              </div>
            </div>
            <div className={"flex flex-col items-center justify-start pt-3"}>
              <SwipeDots index={twibIndex || 0} total={location.twibs.length} />
            </div>
          </>
        )}
      </div>
    </SubView>
  );
};
