import { UserModel } from "../../../lib/types";
import SearchResultUser from "../../../features/user/SearchResultUser";

export type FollowersTabProps = {
  users: UserModel[];
};
export const FollowersTab = ({ users }: FollowersTabProps) => {
  return (
    <div className="mt-2">
      {users.length === 0 && (
        <div
          className={
            "text-center bg-primary-light rounded-lg py-4 text-primary mt-2"
          }
        >
          Friend requests will show up here
        </div>
      )}
      {users.map((user) => {
        return (
          <div className={"mb-4"} key={`follower-${user.id}`}>
            <SearchResultUser user={user} />
          </div>
        );
      })}
    </div>
  );
};
