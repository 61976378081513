import Layout from "../components/Layout";
import { useState } from "react";
import { Button, Progress } from "flowbite-react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { routes } from "../config/routes";

const headerCopy = [
  "Welcome to TWIBS",
  "To get started, look for Twibs on the map",
  "When you find a Twib you like, join",
  "To create a Twib, tap the 'Add Twib' button",
  "To invite friends, look them up by name or username",
];

const subheaderCopy = [
  "An invite-only community that helps you organize your social life by letting you know what your friends are up to",
  "Every Twib is an open-invitation to a specific place: restaurants, hiking trails, bowling alleys — anything on the map",
  "You'll automatically be added to a group chat. You can leave at any time if you decide you can't make it",
  "You can create a Twib anywhere on the map, such as bars, restaurants, or your place. You can add optional fields like date, time, description, and cover photo",
  "Twibs are visible to everyone by default, but mutual friends can add each other to private Twibs",
];

export default function Onboarding() {
  const navigate = useNavigate();
  const NUMBER_OF_STEPS = 4;
  const [step, setStep] = useState<number>(0);

  const handleClick = (incr: number) => {
    return () => {
      const newStep = step + incr;
      setStep(step + incr);
      if (newStep >= NUMBER_OF_STEPS) {
        navigate(routes.mapRoot);
      }
    };
  };

  return (
    <Layout hideMenu fullHeight>
      <div
        style={{
          height: "100vh",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-center items-center bg-primary text-white"
      >
        <div className={"flex flex-col h-full w-full align-center"}>
          <div
            style={{
              fontSize: 28,
              textAlign: "center",
              marginTop: "20vh",
              padding: "0px 24px",
            }}
          >
            {headerCopy[step]}
          </div>
          <div
            style={{
              fontSize: 20,
              textAlign: "center",
              marginTop: "24px",
              padding: "0px 24px",
            }}
          >
            {subheaderCopy[step]}
          </div>
          <div className={"absolute bottom-6 left-4"}>
            <Button
              className={classNames({ invisible: step === 0 })}
              onClick={handleClick(-1)}
            >
              Back
            </Button>
          </div>
          <div className={"absolute bottom-6 right-4"}>
            <Button onClick={handleClick(1)}>
              {step === 3 ? "Finish" : "Next"}
            </Button>
          </div>
          <div className={"absolute bottom-0 w-full"}>
            <Progress
              // color="dark"
              progress={(step / NUMBER_OF_STEPS) * 100}
              size="sm"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
