import { ReactNode } from "react";
import classNames from "classnames";

type SelectListProps = {
  items: {
    label: string;
    value: any;
    isActive: boolean;
    icon?: ReactNode;
    onClick: () => void;
  }[];
};
export const SelectList = ({ items }: SelectListProps) => {
  return (
    <div>
      {items.map(({ onClick, label, value, isActive, icon }, index) => {
        return (
          <div
            key={label}
            className={classNames("flex flex-row justify-between w-full py-4", {
              "border-b": index !== items.length - 1,
            })}
            onClick={onClick}
          >
            <div>{label}</div>
            {icon && (
              <div className={"flex flex-row items-center"}>
                <div className={"text-xl"}>{icon}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
