import {
  PhoneAuthProvider,
  signInWithCredential,
  RecaptchaVerifier,
} from "firebase/auth";
import { auth } from "../config/firebase";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../config/routes";
import { bgImage } from "../config/theme";
import { Button, TextInput } from "flowbite-react";
import { useDispatch } from "react-redux";
import { setUserAuth } from "../features/user/user-slice";
import { createUser } from "../lib/api/users";
import classNames from "classnames";
import LoadingSpinner from "../components/LoadingSpinner";
import BackButton from "../components/BackButton";
import { scopedLogger } from "../lib/utils/logger";
import { UserCredential } from "firebase/auth";

const logger = scopedLogger("LoginView");

type AuthState =
  | "signed-in"
  | "signed-out"
  | "submitting"
  | "verifying"
  | "checking-auth";

export function LoginWrapper({ children }: { children: ReactNode }) {
  return (
    <div
      style={{
        height: "100vh",
        // backgroundImage: `url("${bgImage}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="flex flex-col justify-center items-center bg-primary text-primary-semi-light"
    >
      <div className="mt-20">{children}</div>
    </div>
  );
}

export default function LoginView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [phone, setPhone] = useState<string>("");
  const verifyInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [authState, setAuthState] = useState<AuthState>("verifying");
  const [verificationId, setVerificationId] = useState<string>("");
  const [verificationCodeWasInvalid, setVerificationCodeWasInvalid] =
    useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);

  useEffect(() => {
    if (auth.currentUser) {
      logger("logged in...");
      return navigate(routes.root);
    } else {
      logger("what?");
    }
    setAuthState("signed-out");
  }, [navigate]);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
      setAuthState(!!user ? "signed-in" : "signed-out");

      if (user) {
        const apiUser = await createUser(user.phoneNumber!);
        const payload = {
          user,
          accessToken: await user.getIdToken(),
          uid: user.uid,
        };
        dispatch(setUserAuth(payload));
        // dispatch(setApiUser(apiUser));
        recaptcha?.clear();
        navigate(routes.mapRoot);
      }
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [dispatch, navigate]);

  const handleVerify = async () => {
    setAuthState("checking-auth");
    const verificationCode = verifyInputRef.current!.value;
    const authCredential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    try {
      const userCredential: UserCredential = await signInWithCredential(
        auth,
        authCredential
      );
    } catch (err: any) {
      if (err.code === "auth/invalid-verification-code") {
        recaptcha?.clear();
        setRecaptcha(null);
        logger("invalid verification code");
        setVerificationCodeWasInvalid(true);
      }
    }
  };

  const handleSubmit = async () => {
    setAuthState("submitting");
    const number = `+1${inputRef.current!.value}`;
    setPhone(number);
    const provider = new PhoneAuthProvider(auth);
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      submitButtonRef.current as HTMLElement,
      {
        size: "invisible",
      }
    );
    setRecaptcha(recaptchaVerifier);
    const verificationId = await provider
      .verifyPhoneNumber(number, recaptchaVerifier)
      .catch((err) => {
        logger("caught verification error");
        logger(err);
      });
    if (!verificationId) {
      logger("no verification id");
      return;
    }
    setVerificationId(verificationId);
    setAuthState("verifying");
  };

  switch (authState) {
    case "checking-auth":
      return (
        <LoginWrapper>
          {verificationCodeWasInvalid && (
            <div className={"mx-14 flex flex-col items-center text-center"}>
              <div>
                The entered verification code wasn't quite right - please try
                again.
              </div>
              <BackButton
                text={"Go Back"}
                onClick={() => {
                  setVerificationCodeWasInvalid(false);
                  setAuthState("signed-out");
                }}
              />
            </div>
          )}
          {!verificationCodeWasInvalid && <LoadingSpinner />}
        </LoginWrapper>
      );

    case "verifying":
      return (
        <LoginWrapper>
          <div className="flex max-w-md flex-col gap-4">
            <p className={"text-lg text-primary-semi-light"}>
              Enter the six-digit code:
            </p>
            <TextInput
              type="text"
              ref={verifyInputRef}
              className={"items-center"}
              placeholder={"Verification Code"}
              theme={{
                field: {
                  input: {
                    base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 text-center rounded-full",
                    withAddon: {
                      off: "rounded-full",
                    },
                  },
                },
              }}
            />
            <Button color={"warning"} onClick={handleVerify} pill>
              Verify
            </Button>
          </div>
        </LoginWrapper>
      );
    case "submitting":
    case "signed-out":
      return (
        <LoginWrapper>
          <form className="flex max-w-md flex-col gap-4 items-center">
            <div className={"flex flex-col gap-4"}>
              <div
                className={
                  "text-center text-2xl font-bold text-primary-semi-light"
                }
              >
                Welcome to TWIBS
              </div>
              {authState === "submitting" ? (
                <div className={"text-center"}>
                  <LoadingSpinner />
                </div>
              ) : (
                <div>
                  <TextInput
                    theme={{
                      addon:
                        "inline-flex items-center rounded-l-full border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
                      field: {
                        input: {
                          withAddon: {
                            on: "rounded-r-full",
                          },
                        },
                      },
                    }}
                    ref={inputRef}
                    id="phone_number"
                    addon="+1"
                    placeholder="Phone Number"
                    required
                    type="text"
                  />
                </div>
              )}
              <Button
                className={classNames("", {
                  invisible: authState === "submitting",
                })}
                color={"warning"}
                type="button"
                pill
                ref={submitButtonRef}
                onClick={handleSubmit}
              >
                Continue
              </Button>
            </div>
            {authState === "signed-out" && (
              <div className={"text-xs font-light fixed bottom-10 mx-12"}>
                <div className={"mb-2"}>
                  We will text you to confirm your number. Standard message and
                  data rates apply.
                </div>
                <div>
                  By continuing, you agree to our{" "}
                  <a className={"underline"} href="https://twibs.io/terms">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a className={"underline"} href="https://twibs.io/privacy">
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
            )}
          </form>
        </LoginWrapper>
      );
    default:
      return <div>loading...</div>;
  }
}
