import { generatePath, useNavigate } from "react-router-dom";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useParams } from "react-router";
import {
  ChatUserModel,
  ChatUserStatus,
  MessageModel,
  MessageThreadModel,
  UserModel,
  WebsocketMessageEvent,
} from "../../lib/types";
import logger from "../../lib/utils/logger";
import * as eventBus from "../../lib/utils/event-bus";
import { fetchThread } from "../../lib/api/message-threads";
import { routes } from "../../config/routes";
import { IoChevronBack } from "react-icons/io5";
import { sendMessage } from "../../lib/api/messages";
import { DateTime } from "luxon";
import { SubView } from "./SubView";
import LoadingSpinner from "../../components/LoadingSpinner";
import { MessagesList } from "../../components/messaging/thread/MessageList";
import { Button, TextInput } from "flowbite-react";
import { RiMailSendLine } from "react-icons/ri";
import { addFriend, fetchAllConnections } from "../../lib/api/friends";
import { setAllConnections } from "../../features/user/user-slice";
import { roundedTextInputTheme } from "../../config/theme";
import { twibDetailDate, twibDetailTime } from "../../lib/utils/date";
import { PhotoUrl } from "../../lib/utils/photo-url";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { chatUserStatus } from "../../lib/dictionary";

const RsvpList = ({
  appUser,
  thread,
  acceptedUsers,
  declinedUsers,
  pendingUsers,
}: {
  appUser: UserModel;
  thread: MessageThreadModel;
  acceptedUsers: ChatUserModel[];
  declinedUsers: ChatUserModel[];
  pendingUsers: ChatUserModel[];
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const connections = useAppSelector((state) => state.user.connections);
  const [connectionsFetched, setConnectionsFetched] = useState<boolean>(false);
  const [isAddingFriend, setIsAddingFriend] = useState<boolean>(false);

  // keep an object in state to track who has been added as a friend
  const [friendAddedMap, setFriendAddedMap] = useState<{
    [key: number]: boolean;
  }>({});

  const handleAddFriend = async (user: UserModel) => {
    setIsAddingFriend(true);
    const result = await addFriend(user);
    dispatch(setAllConnections(result));
    setFriendAddedMap({ ...friendAddedMap, [user.id]: true });
    setIsAddingFriend(false);
  };

  useEffect(() => {
    if (!connectionsFetched) {
      fetchAllConnections().then((connections) => {
        dispatch(setAllConnections(connections));
        setConnectionsFetched(true);
      });
    }
  }, [connectionsFetched, dispatch]);

  const isFollowing = (userId: number): boolean => {
    return [...connections.friends, ...connections.following].some(
      (connection) => connection.id === userId
    );
  };

  const UserList = ({ users }: { users: (UserModel | ChatUserModel)[] }) => {
    return (
      <div>
        {users.map((user, i) => {
          const isMe = appUser.id === user.id;
          const handleUserClicked = () => {
            navigate(generatePath(routes.map, { view: "user", id: user.id }));
          };
          return (
            <div
              key={i}
              className={"mb-2 flex flex-row justify-between items-center"}
            >
              <div
                className={
                  "flex flex-row justify-around text-gray-300 text-xs items-center"
                }
                onClick={isMe ? () => {} : handleUserClicked}
              >
                <img
                  src={PhotoUrl.user(user).thumb}
                  alt={PhotoUrl.user(user).thumb}
                  className={"rounded-full w-10 h-10 mr-2"}
                />
                <div className="mr-1 text-white">{user.first_name}</div>
                <div className="mr-1 italic">@{user.username}</div>
              </div>
              <div>
                {appUser!.id !== user.id && !isFollowing(user.id) && (
                  <Button
                    size="xs"
                    loading={isAddingFriend}
                    onClick={() => handleAddFriend(user)}
                  >
                    <p>Add Friend</p>
                  </Button>
                )}
                {friendAddedMap[user.id] && (
                  <p className="text-white text-xs">✓ Added</p>
                )}
                {isMe && <div className="text-white text-xs">You</div>}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const RsvpHeader = ({ children }: PropsWithChildren) => {
    return (
      <div className={"text-gray-300 text-xs font-bold mb-1"}>{children}</div>
    );
  };

  return (
    <div className={"px-4 w-full mt-4"}>
      {!connectionsFetched && <LoadingSpinner />}
      {connectionsFetched && <UserList users={acceptedUsers} />}
      {connectionsFetched && pendingUsers.length > 0 && (
        <div className={"mt-4"}>
          <RsvpHeader>Invited:</RsvpHeader>
          <UserList users={pendingUsers} />
        </div>
      )}
    </div>
  );
};

const TwibDetails = ({
  thread,
  onShowRsvps,
  isShowingRsvps,
  onBack,
}: {
  thread: MessageThreadModel;
  onShowRsvps: (show: boolean) => void;
  isShowingRsvps: boolean;
  onBack: () => void;
}) => {
  const { twib } = thread;
  const appUser = useAppSelector((state) => state.user.user);

  const personPeople = (count: number): string => {
    return count === 1 ? "Person" : "People";
  };

  const usersOfRsvpStatus = (status: ChatUserStatus) => {
    return (user: ChatUserModel): boolean => {
      return user.pivot.status === status;
    };
  };

  const acceptedUsers = thread.users.filter(
    usersOfRsvpStatus(chatUserStatus.accepted)
  );
  const declinedUsers = thread.users.filter(
    usersOfRsvpStatus(chatUserStatus.declined)
  );
  const pendingUsers = thread.users.filter(
    usersOfRsvpStatus(chatUserStatus.invited)
  );

  return (
    <div className={"text-primary px-2 mb-3"}>
      <div
        className={
          "absolute flex flex-row text-white font-bold justify-start mb-2"
        }
      >
        <IoChevronBack className={"mr-1"} size={22} onClick={onBack} />
      </div>
      <div
        style={{
          fontSize: 18,
          paddingBottom: 8,
        }}
        className={"flex flex-row text-white font-bold justify-center mb-2"}
      >
        {thread.twib.location.name}
      </div>
      <div
        className={
          "flex flex-row justify-around items-center text-gray-300 text-xs"
        }
      >
        <div>
          🗓&nbsp;
          {twibDetailDate(twib)}
        </div>
        <div>
          ⏰&nbsp;
          {twibDetailTime(twib)}
        </div>
        <div
          className={"flex flex-row"}
          onClick={() => onShowRsvps(!isShowingRsvps)}
        >
          ℹ️&nbsp;
          {acceptedUsers.length} {personPeople(acceptedUsers.length)}{" "}
          Going&nbsp;
          {!isShowingRsvps ? (
            <BiChevronDown fontSize={18} />
          ) : (
            <BiChevronUp fontSize={18} />
          )}
        </div>
      </div>
      {isShowingRsvps && (
        <div className={"flex flex-row"} onClick={() => onShowRsvps(true)}>
          {appUser && (
            <RsvpList
              appUser={appUser}
              thread={thread}
              acceptedUsers={acceptedUsers}
              declinedUsers={declinedUsers}
              pendingUsers={pendingUsers}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default function MessageThreadSubView() {
  const navigate = useNavigate();
  const messageInputRef = useRef<HTMLInputElement>(null);
  const appUser = useAppSelector((state) => state.user.user);
  const { id } = useParams();
  const [thread, setThread] = useState<MessageThreadModel | null>(null);
  const [isShowingRsvps, setIsShowingRsvps] = useState<boolean>(false);

  const handleMessageReceived = (event: WebsocketMessageEvent) => {
    logger("adding new message", event.payload.message);
    setThread({
      ...thread!,
      messages: [...thread!.messages, event.payload.message],
    });
  };

  useEffect(() => {
    eventBus.on("message_received", handleMessageReceived);
    return () => eventBus.off("message_received", handleMessageReceived);
  });

  useEffect(() => {
    if (typeof id !== "undefined" && appUser !== null) {
      fetchThread(parseInt(id!, 10)).then((thread) => {
        setThread(thread);
      });
    }
  }, [id, appUser]);

  const handleBack = () => {
    navigate(generatePath(routes.map, { view: "twib", id: thread?.twib.id }));
  };
  const handleViewAllMessages = () => {
    navigate(generatePath(routes.messages));
  };

  const handleShowRsvps = (show: boolean) => {
    setIsShowingRsvps(show);
  };

  const handleSendMessage = async () => {
    const text = messageInputRef.current!.value;
    if (!text) return;
    await sendMessage(thread!, appUser!, text);
    messageInputRef.current!.value = "";
    handleMessageReceived({
      event: "message_received",
      payload: {
        message: {
          id: +new Date(),
          text,
          created_at: DateTime.utc().toSQL(),
          user_id: appUser!.id,
          user: appUser,
        } as MessageModel,
      },
    });
  };

  return (
    <SubView>
      <div
        style={{
          height: "calc(100vh - 100px)",
        }}
        className={"bg-primary mt-8 p-2 flex flex-col rounded-3xl m-4"}
      >
        {(appUser === null || thread === null) && <LoadingSpinner />}
        {appUser !== null && thread !== null && (
          <>
            <div className={"mt-2"}>
              <TwibDetails
                thread={thread}
                onShowRsvps={handleShowRsvps}
                isShowingRsvps={isShowingRsvps}
                onBack={() => navigate(-1)}
              />
            </div>
            <div
              style={{
                height: "100%",
                justifyContent: "flex-end",
                overflow: "auto",
              }}
              className={
                "flex flex-end flex-col bg-primary-light rounded-2xl pb-2 pt-4"
              }
            >
              <MessagesList user={appUser} thread={thread} />
              <div className={"flex flex-row items-center px-2"}>
                <TextInput
                  theme={roundedTextInputTheme}
                  ref={messageInputRef}
                  className={"mr-2 rounded-full flex-grow"}
                  placeholder={"Send a message..."}
                />
                <Button
                  pill
                  className={"h-full bg-gradient-button"}
                  onClick={handleSendMessage}
                  style={{
                    height: 40,
                    width: 41,
                  }}
                >
                  <RiMailSendLine />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </SubView>
  );
}
