import { PaymentTypeModel } from "../../lib/types";
import { SelectRow } from "./SelectRow";
import { paymentTypeDetails } from "../../lib/dictionary";

type SetPaymentTypeProps = {
  paymentType: PaymentTypeModel | null;
  paymentTypes: PaymentTypeModel[] | null;
  setPaymentType: (paymentType: PaymentTypeModel | null) => void;
};
export const SetPaymentType = ({
  paymentType,
  paymentTypes,
  setPaymentType,
}: SetPaymentTypeProps) => {
  const handleTypeSelected = (type: PaymentTypeModel) => {
    return () => {
      setPaymentType(type);
    };
  };
  return (
    <div>
      {paymentTypes?.map((type, index) => {
        return (
          <SelectRow
            key={type.name}
            onClick={handleTypeSelected(type)}
            label={type.name}
            prefix={paymentTypeDetails[type.name].icon || null}
            badge={""}
            isFirst={index === 0}
          />
        );
      })}
    </div>
  );
};
