import { UserModel } from "../lib/types";
import CircleAvatar from "./CircleAvatar";
import CircleButton from "./CircleButton";
import IconEditProfile from "./icons/IconEditProfile";
import IconSettings from "./icons/IconSettings";
import IconFriends from "./icons/IconFriends";
import { useLocation } from "react-router";
import { routes } from "../config/routes";
import { generatePath, useNavigate } from "react-router-dom";
import { Button } from "flowbite-react";
import { PiMapPinThin } from "react-icons/pi";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { setIsAddingTwib } from "../features/map/map-slice";
import { PhotoUrl } from "../lib/utils/photo-url";
import { bgImage } from "../config/theme";
import { setIsMenuOpen } from "../features/app-slice";
import { FaMapMarkerAlt } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import twibsLogo from "../assets/images/twibs-icon-no-background.png";

export type MenuProps = {
  user: UserModel;
};

function MenuItem({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) {
  return (
    <div
      onClick={onClick}
      className={
        "flex flex-row items-center mb-1 p-1 uppercase font-light text-xs bg-primary-light rounded-full border border-gray-300"
      }
    >
      {children}
    </div>
  );
}

function MenuItemLabel({ children }: { children: React.ReactNode }) {
  return (
    <span
      className={"ml-1 pr-4 font-bold text-gray-700"}
      style={{ userSelect: "none" }}
    >
      {children}
    </span>
  );
}

export default function Menu({ user }: MenuProps) {
  const location = useLocation();
  const appUser = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isActive = useAppSelector((state) => state.app.isMenuOpen);

  const handleAvatarClicked = () => {
    dispatch(setIsMenuOpen(!isActive));
  };

  const handleAddTwibClicked = () => {
    dispatch(setIsAddingTwib(true));
  };

  const handleCloseMenu = () => {
    dispatch(setIsMenuOpen(false));
  };

  const handleOnClickItem = (cb: () => void) => {
    return () => {
      dispatch(setIsMenuOpen(false));
      cb();
    };
  };

  const onOutsideTap = () => {
    if (isActive) {
      handleCloseMenu();
    }
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "fit-content",
          top: "-61px",
          left: "5px",
          zIndex: "102",
        }}
      >
        <AnimatePresence>
          {isActive && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0 }}
              style={{
                position: "absolute",
                width: "fit-content",
                top: "-281px",
                zIndex: "102",
              }}
              className="flex flex-col items-start"
            >
              <MenuItem
                onClick={handleOnClickItem(() => navigate(routes.profile))}
              >
                <CircleButton active={location.pathname === routes.profile}>
                  <IconEditProfile />
                </CircleButton>{" "}
                <MenuItemLabel>Profile</MenuItemLabel>
              </MenuItem>
              <MenuItem
                onClick={handleOnClickItem(() =>
                  navigate(generatePath(routes.messages, { tab: "active" }))
                )}
              >
                <CircleButton active={location.pathname === routes.messages}>
                  <FaMapMarkerAlt size={25} />
                </CircleButton>{" "}
                <MenuItemLabel>TWIBS</MenuItemLabel>
              </MenuItem>
              <MenuItem
                onClick={handleOnClickItem(() =>
                  navigate(generatePath(routes.people, { tab: "friends" }))
                )}
              >
                <CircleButton active={location.pathname === routes.people}>
                  <IconFriends />
                </CircleButton>{" "}
                <MenuItemLabel>Friends</MenuItemLabel>
              </MenuItem>
              <MenuItem
                onClick={handleOnClickItem(() =>
                  navigate(generatePath(routes.map, { view: "settings" }))
                )}
              >
                <CircleButton active={location.pathname === routes.settings}>
                  <IconSettings />
                </CircleButton>{" "}
                <MenuItemLabel>Settings</MenuItemLabel>
              </MenuItem>
            </motion.div>
          )}
        </AnimatePresence>
        <div
          style={{
            position: "relative",
            width: "fit-content",
            display: "flex",
            float: "inline-start",
          }}
        >
          <CircleAvatar
            imageUri={PhotoUrl.user(user).thumb}
            onClick={handleAvatarClicked}
          />
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          width: "fit-content",
          top: "-58px",
          right: "5px",
        }}
      >
        {routes.mapRoot === location.pathname.replace(/\/$/, "") && (
          <Button
            pill
            size={"xl"}
            className={"text-primary"}
            onClick={handleAddTwibClicked}
            style={{
              background: "white",
              border: "1px solid grey",
              backgroundImage:
                "linear-gradient(120deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%)",
              backgroundSize: "cover",
            }}
          >
            <img src={twibsLogo} className={"mr-1"} height={24} width={24} />
            {/*<PiMapPinThin className={"mr-1"} fontSize={24} />*/}
            Add Twib
          </Button>
        )}
      </div>
    </>
  );
}
