import { CustomFlowbiteTheme, FlowbiteTheme, ThemeProps } from "flowbite-react";
import bg0 from "../assets/images/login-bg-new-new-old.png";
import bg from "../assets/images/login-bg-new.png";
import bg2 from "../assets/images/twibs-pencil-bg.jpg";
import bg3 from "../assets/images/login-bg-variation-1.png";
import bg4 from "../assets/images/login-bg-variation-2.png";
import bg5 from "../assets/images/login-bg-variation-3.png";
import bg6 from "../assets/images/login-bg-variation-4.png";
import bg7 from "../assets/images/login-bg-variation-5.png";
import bg8 from "../assets/images/login-bg-variation-6.png";
import bg9 from "../assets/images/login-bg-variation-7.png";
import bg10 from "../assets/images/login-bg-variation-8.png";
import bgGradient from "../assets/images/bg-gradient.png";
import bgLime from "../assets/images/bg-lime.png";
import { LiaCalendarAltSolid, LiaClock } from "react-icons/lia";

export const bgImage = bgGradient;
export const bgImages = [bg, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10];

export const appTheme: ThemeProps = {
  // button: {
  //   // primary: "bg-red-500 hover:bg-red-600 text-blue-500",
  // },
};

export const icons = {
  calendar: ({ className }: { className?: string }) => (
    <LiaCalendarAltSolid className={className || ""} />
  ),
  clock: ({ className }: { className?: string }) => (
    <LiaClock className={className || ""} />
  ),
};

export const roundedTextInputTheme = {
  field: {
    input: {
      base: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 rounded-full",
      withAddon: {
        off: "rounded-full",
      },
    },
  },
};
