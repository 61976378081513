import { useEffect, useState } from "react";
import App from "../App";
import LoadingSpinner from "../components/LoadingSpinner";
import { Button, Modal, ToggleSwitch } from "flowbite-react";
import InstallInstructions from "../components/InstallInstructions";
import { useRollbar } from "@rollbar/react";
import { isAppInstalled } from "../lib/utils/pwa";

export default function Permissions() {
  const rollbar = useRollbar();
  const [notificationsPossible, setNotificationsPossible] = useState<
    boolean | null
  >(true);
  const [notificationsStatus, setNotificationsStatus] = useState<
    PermissionStatus["state"] | null
  >(null);
  const [locationsStatus, setLocationsStatus] = useState<
    PermissionStatus["state"] | null
  >(null);
  const [isAppAlreadyInstalled, setIsAppAlreadyInstalled] = useState<
    boolean | null
  >(null);
  const [debugMessage, setDebugMessage] = useState<string>("");

  const [showInstallInstructions, setShowInstallInstructions] =
    useState<boolean>(false);
  const [forceSkip, setForceSkip] = useState<boolean>(false);
  const [installedApps, setInstalledApps] = useState<any[]>([]);
  const [checkedInstalledApps, setCheckedInstalledApps] =
    useState<boolean>(false);
  const [showDebug, setShowDebug] = useState<boolean>(false);

  useEffect(() => {
    if (!checkedInstalledApps) {
      setCheckedInstalledApps(true);
      if ("getInstalledRelatedApps" in window.navigator) {
        // @ts-ignore
        window.navigator.getInstalledRelatedApps().then(setInstalledApps);
      }
    }
  }, [checkedInstalledApps]);

  useEffect(() => {
    if (
      notificationsStatus === null &&
      locationsStatus === null &&
      isAppAlreadyInstalled === null
      // notificationsPossible === null
    ) {
      isAppInstalled().then(setIsAppAlreadyInstalled);
      window.navigator.permissions
        .query({ name: "geolocation" })
        .then((status) => {
          setLocationsStatus(status.state);
          status.addEventListener("change", () => {
            setLocationsStatus(status.state);
          });
        });
      window.navigator.permissions
        .query({ name: "notifications" })
        .then((status) => {
          setNotificationsStatus(status.state);
          status.addEventListener("change", () => {
            setNotificationsStatus(status.state);
          });
        })
        .catch((err) => {
          setNotificationsStatus("denied");
          setDebugMessage("Error getting notifications status: " + err.message);
        });
      // setNotificationsPossible("Notification" in window);
    }
  }, [notificationsPossible, notificationsStatus, locationsStatus]);

  const notificationsAllowed = notificationsStatus === "granted";
  const locationAllowed = locationsStatus === "granted";

  const handleShowInstallInstructions = () => {
    setShowInstallInstructions(true);
  };

  if (
    forceSkip ||
    (notificationsStatus === "granted" && locationsStatus === "granted")
  ) {
    return <App />;
  }

  if (
    notificationsPossible === null ||
    notificationsStatus === null ||
    locationsStatus === null ||
    isAppAlreadyInstalled === null
  ) {
    return (
      <div
        className={"flex flex-row justify-center items-center w-full h-full"}
      >
        {showDebug && (
          <div className={"absolute top-0 left-0 p-4"}>
            <div className={"text-gray-100"}>
              Notifications Possible:{" "}
              {notificationsPossible === null
                ? "null"
                : notificationsPossible.toString()}
            </div>
            <div className={"text-gray-100"}>
              Notifications Status:{" "}
              {notificationsStatus === null
                ? "null"
                : notificationsStatus.toString()}
            </div>
            <div className={"text-gray-100"}>
              Locations Status:{" "}
              {locationsStatus === null ? "null" : locationsStatus.toString()}
            </div>
            <div className={"text-gray-100"}>
              App Installed:{" "}
              {isAppAlreadyInstalled === null
                ? "null"
                : isAppAlreadyInstalled.toString()}
            </div>
            <div className={"text-gray-100"}>
              Installed Apps:{" "}
              {installedApps.map((app) => app.platform).join(", ")}
            </div>
            <div className={"text-gray-100"}>DebugMessage: {debugMessage}</div>
          </div>
        )}
        <div onClick={() => setShowDebug(true)}>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  const Section = ({ children }: { children: React.ReactNode }) => (
    <div className={"flex flex-col justify-center items-start mb-4"}>
      {children}
    </div>
  );
  const toggleTheme = {
    root: {
      label: "ml-3 text-sm font-medium text-gray-100 dark:text-gray-300",
    },
  };

  let notifications = null;
  if (!notificationsPossible) {
    notifications = (
      <Section>
        <div>
          <span className={"text-yellow-700"}>Notifications</span> permissions
          are required for this app to work properly. Unfortunately it seems
          like your device does not support this functionality.
        </div>
      </Section>
    );
  } else if (notificationsPossible && !(notificationsStatus === "granted")) {
    notifications = (
      <Section>
        <div className={"mb-3"}>
          Your device supports notifications, but are not currently allowed.
        </div>
        <ToggleSwitch
          theme={toggleTheme}
          checked={false}
          label="Allow Notifications"
          onChange={function () {
            Notification.requestPermission().then((permission) => {
              setNotificationsStatus(
                permission === "granted" ? "granted" : "denied"
              );
            });
          }}
        />
      </Section>
    );
  } else {
    notifications = (
      <Section>
        <div className={"mb-3"}>
          Your device supports notifications and they are currently allowed.
        </div>
        <ToggleSwitch
          theme={toggleTheme}
          checked={true}
          label="Allow Notifications"
          onChange={() => {}}
        />
      </Section>
    );
  }

  let location = null;
  if (!locationAllowed) {
    location = (
      <Section>
        <div className={"mb-3"}>
          We use your location to show you the most relevant content.{" "}
          <span className={"italic"}>
            We never save your location, and no one else can see it.
          </span>
        </div>
        <ToggleSwitch
          checked={false}
          theme={toggleTheme}
          label="Allow Location Access"
          onChange={function () {
            navigator.geolocation.getCurrentPosition(
              () => {
                setLocationsStatus("granted");
              },
              (err) => {
                rollbar.error(
                  "Unable to get location",
                  {
                    error_message: err.message,
                    error_code: err.code,
                    error_permission_denied: err.PERMISSION_DENIED,
                    error_position_unavailable: err.POSITION_UNAVAILABLE,
                  },
                  err
                );
                setLocationsStatus("denied");
              }
            );
          }}
        />
      </Section>
    );
  } else {
    location = (
      <Section>
        <div className={"mb-3"}>
          We use your location to show you the most relevant content.{" "}
          <span className={"italic"}>
            We never save your location, and no one else can see it.
          </span>
        </div>
        <ToggleSwitch
          className={"text-gray-100"}
          checked={true}
          label="Allow Location Access"
          onChange={() => {}}
          theme={toggleTheme}
        />
      </Section>
    );
  }

  const SectionTitle = ({ text }: { text: string }) => {
    return <div className={"text-xl font-bold mb-2"}>{text}</div>;
  };
  return (
    <div
      className={
        "flex flex-col justify-center items-start h-full bg-gradient-permissions"
      }
    >
      <div
        className={"text-gray-200 font-bold text-3xl fixed top-16 ml-4"}
        onClick={() => setForceSkip(true)}
      >
        TWIBS
      </div>
      <div className={"flex flex-col justify-center items-start h-full px-4"}>
        {!isAppAlreadyInstalled && (
          <div>
            <div className={"italic text-2xl text-center px-4"}>
              This app uses features that require it to be installed
            </div>
            <div className={"mt-6 text-center flex flex-row justify-center"}>
              <Button
                className={"-full"}
                onClick={handleShowInstallInstructions}
              >
                Show Install Instructions
              </Button>
            </div>
          </div>
        )}
        {isAppAlreadyInstalled && (
          <>
            <div className={"mb-4 "}>
              This app uses some experimental features. As such,{" "}
              <span className={"italic"}>
                the following permissions are required:
              </span>
            </div>
            <div className={"flex flex-col justify-center"}>
              <SectionTitle text={"Notifications"} />
              {notifications}

              <SectionTitle text={"Location"} />
              {location}
            </div>
          </>
        )}
      </div>
      <Modal
        dismissible
        show={showInstallInstructions}
        onClose={() => setShowInstallInstructions(false)}
      >
        <Modal.Body>
          <div className="space-y-6">
            <InstallInstructions
              onClose={() => setShowInstallInstructions(false)}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
