import { MessageModel } from "../../../lib/types";
import { MessageDate } from "./MessageDate";

export const AppUserMessage = ({ message }: { message: MessageModel }) => {
  return (
    <div className={"flex flex-col justify-start items-end w-full pt-2 pb-2"}>
      <div className={"flex flex-row justify-end items-center mb-1"}>
        <div className={"flex flex-col justify-around items-start"}>
          <MessageDate message={message} />
        </div>
      </div>
      <div className={"text-right"}>{message.text}</div>
    </div>
  );
};
