import { NewTwibLocationModel } from "../../lib/types";
import MapSearchBar from "../MapSearchBar";

export default function LocationStep({
  setSearchResult,
  onNext,
  onClose,
}: {
  setSearchResult: (searchResult: NewTwibLocationModel) => void;
  onNext: () => void;
  onClose: () => void;
}) {
  const handleLocationSelected = (searchResult: NewTwibLocationModel) => {
    setSearchResult(searchResult);
    onNext();
  };

  return (
    <MapSearchBar
      onClose={onClose}
      onLocationSelected={handleLocationSelected}
    />
  );
}
