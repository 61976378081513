import React, { PropsWithChildren } from "react";
import { Button } from "flowbite-react";
import classNames from "classnames";
import { IoClose } from "react-icons/io5";

export type SubViewHeaderProps = PropsWithChildren & {
  title?: string;
  onClose?: () => void;
  topBar?: React.ReactNode;
  height?: number;
};
export const SubViewHeader = ({
  title,
  children,
  height = 150,
}: SubViewHeaderProps) => {
  return (
    <div
      style={{ height }}
      className={"bg-primary p-4 top-0 bottom-0 right-0 left-0"}
    >
      <div style={{ fontSize: 20 }}>{title}</div>
      {children}
    </div>
  );
};
