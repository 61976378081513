import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import Layout from "../components/Layout";
import mapboxgl from "../config/mapbox";
import AddTwib from "../components/add-twib/AddTwib";
import "mapbox-gl/dist/mapbox-gl.css";
import { LocationModel, SlimLocationModel, TwibModel } from "../lib/types";
import { createRoot } from "react-dom/client";
import { matchedLocations } from "../lib/api/locations";
import LoadingSpinner from "../components/LoadingSpinner";
import { LocationTwibDetailsSubView } from "./sub-views/LocationTwibDetailsSubView";
import initializeMapWithUserLocation from "../features/map/initialize-map-with-user-location";
import markerEventHandler from "../features/map/marker-event-handler";
import logger from "../lib/utils/logger";
import { useParams } from "react-router";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "../config/routes";
import MessagesSubView from "./sub-views/MessagesSubView";
import PeopleSubView from "./sub-views/people/PeopleSubView";
import OtherUserSubview from "./sub-views/OtherUserSubview";
import {
  selectFilteredLocations,
  setCurrentMapCenter,
  setLocations,
} from "../features/map/map-slice";
import { off, on } from "../lib/utils/event-bus";
import LocationMarker from "../components/LocationMarker";
import TwibTray from "../components/TwibTray";
import { setIsMenuOpen } from "../features/app-slice";
import SettingsSubView from "./sub-views/SettingsSubView";
import ProfileSubView from "./sub-views/profile/ProfileSubView";
import { twibsFromLocations } from "../features/map/twibs-from-locations";
import { setTwibToDelete } from "../features/config-slice";

declare global {
  interface Window {
    popmotionXL: any;
  }
}

interface MapViewProps {}

const MapView: React.FC<MapViewProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { view, id } = useParams();
  const appUser = useAppSelector((state) => state.user.user);
  const userAuth = useAppSelector((state) => state.user.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const userCoordinates = useAppSelector((state) => state.map.coordinates);
  const [previousMarkers, setPreviousMarkers] = useState<mapboxgl.Marker[]>([]);
  const [closeFuncs, setCloseFuncs] = useState<Function[]>([]);
  const [closeEverything, setCloseEverything] = useState<boolean>();
  const fetchedLocations = useAppSelector(selectFilteredLocations);
  const [twibListOpen, setTwibListOpen] = useState<boolean>(true);
  const isMenuOpen = useAppSelector((state) => state.app.isMenuOpen);
  const twibToDelete = useAppSelector((state) => state.config.twibToDelete);

  const getLocations = () => {
    console.log("get locations");
    matchedLocations(userCoordinates.latitude, userCoordinates.longitude)
      .then((locations) => {
        dispatch(setLocations(locations));
        // Clear existing markers, if any
      })
      .catch(() => {
        dispatch(setLocations([]));
      })
      .finally(() => {
        setHasFetchedLocations(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log({ view });
    if (view === "refresh") {
      getLocations();
      navigate(generatePath(routes.mapRoot));
    }
  }, [view]);

  const onOutsideTap = () => {
    if (isMenuOpen) {
      dispatch(setIsMenuOpen(false));
    }
  };

  const [hasFetchedLocations, setHasFetchedLocations] =
    useState<boolean>(false);
  const zoom = 9;

  const handleSubViewClose = () => {
    navigate(routes.mapRoot);
    // setSubView(null);
  };

  const moveMapTo = ({ lat, lng }: { lat: number; lng: number }) => {
    if (!map.current) {
      return;
    }

    map.current.flyTo({
      center: [lng, lat],
      offset: [0, 0],
      zoom: 11,
    });
  };

  const handleMarkerTap = (location: SlimLocationModel) => {
    navigate(generatePath(routes.map, { view: "location", id: location.id }));
  };

  useEffect(() => {
    if (closeEverything) {
      closeFuncs.forEach((f) => {
        f();
      });

      setCloseEverything(false);
    }
  }, [closeEverything]);

  useEffect(() => {
    if (appUser === null || userAuth === null || appUser.status !== "active") {
      return;
    }

    if (!map.current && mapContainer.current) {
      initializeMapWithUserLocation(
        dispatch,
        map,
        mapContainer,
        zoom,
        setTwibListOpen
      );
    }
  }, [appUser, userAuth, dispatch, zoom, mapContainer.current, map.current]);

  useEffect(() => {
    setLoading(true);
    const script = document.createElement("script");
    script.src = "https://unpkg.com/popmotion@7.8.2/dist/popmotion.xl.min.js";
    script.async = true;
    document.body.appendChild(script);

    // initialize the map center
    dispatch(
      setCurrentMapCenter({
        latitude: userCoordinates.latitude,
        longitude: userCoordinates.longitude,
        zoom: 10,
      })
    );

    on("move-map", function () {
      logger("on map move", arguments);
      moveMapTo(arguments[0]);
    });

    return () => {
      off("move-map", moveMapTo);
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!map.current) return;

    if (!hasFetchedLocations) {
      getLocations();
    }
  }, [hasFetchedLocations, loading, map.current]);

  const wrapSetPreviousMarkers = (markers: any[]) => {
    setPreviousMarkers(markers);
  };

  useEffect(() => {
    if (!map.current || !mapContainer.current) return;
    previousMarkers.forEach((marker) => {
      marker.remove();
    });

    // store the close function for each marker to close them later when you open another one
    let newCloseFuncs: any = [];

    // Create new markers for each fetched location and add them to the map
    const markers = fetchedLocations.map((location) => {
      const markerObj = (
        <LocationMarker appUser={appUser!} location={location} />
      );
      const placeholder = document.createElement("div");
      const root = createRoot(placeholder);
      root.render(markerObj);
      const markerEl = placeholder;
      const marker = new mapboxgl.Marker(markerEl).setLngLat([
        location.longitude,
        location.latitude,
      ]);

      setTimeout(() => {
        const closeMarker = markerEventHandler(
          markerEl,
          location,
          map.current!,
          setCloseEverything,
          handleMarkerTap,
          useAppSelector
        );
        newCloseFuncs.push(closeMarker);
      }, 100);
      marker.addTo(map.current!);

      setTimeout(() => {
        setCloseFuncs([...newCloseFuncs]);
      }, 0);
      return marker;
    });

    wrapSetPreviousMarkers(markers);
  }, [fetchedLocations]);

  let headerText: string | ReactNode =
    view === "messages" ? "Messages" : !view ? "Map" : " ";

  return (
    <Layout header={headerText} fullHeight hideMenu>
      <div className={"h-full"} onClick={onOutsideTap}>
        {loading && (
          <div className={"h-full flex flex-col justify-center items-center"}>
            <LoadingSpinner size="xl" />
          </div>
        )}
        <div ref={mapContainer} className="h-full map-container" />

        {!view && (
          <TwibTray
            twibs={twibsFromLocations(fetchedLocations)}
            isOpen={twibListOpen}
            onReOpen={() => {
              setTwibListOpen(true);
              dispatch(setIsMenuOpen(false));
            }}
            isLoading={loading}
          />
        )}

        {/* subviews */}
        {(view === "location" || view === "twib") && (
          <LocationTwibDetailsSubView onClose={handleSubViewClose} />
        )}
        {view === "messages" && <MessagesSubView />}
        {view === "people" && <PeopleSubView />}
        {view === "profile" && <ProfileSubView />}
        {view === "user" && <OtherUserSubview />}
        {view === "settings" && <SettingsSubView />}
      </div>
      <AddTwib />
    </Layout>
  );
};

export default MapView;
