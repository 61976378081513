import { AllConnections, UserModel, VisibilityType } from "../../lib/types";
import { useEffect, useState } from "react";
import { fetchAllConnections } from "../../lib/api/friends";
import { visibilityTypeOptions, visibilityTypes } from "../../lib/dictionary";
import { BiChevronRight, BiEdit } from "react-icons/bi";
import BackButton from "../BackButton";
import { FriendList } from "./FriendsList";
import { SelectList } from "./SelectList";

export type SetInvitesProps = {
  visibilityType: VisibilityType;
  invites: UserModel[] | null;
  setValues: (type: VisibilityType, invites: UserModel[] | null) => void;
};
export const SetInvites = ({
  visibilityType,
  invites,
  setValues,
}: SetInvitesProps) => {
  const [connections, setConnections] = useState<AllConnections | null>(null);
  const [showFriendsList, setShowFriendsList] = useState<boolean>(false);
  const [selectedType, setSelectedType] =
    useState<VisibilityType>(visibilityType);
  const [selectedFriends, setSelectedFriends] = useState<UserModel[]>(
    invites || []
  );

  useEffect(() => {
    if (connections === null) {
      fetchAllConnections().then(setConnections);
    }
  }, [connections]);

  const handleTypeSet = (type: VisibilityType) => {
    return () => {
      if (type !== visibilityTypes.private) {
        setValues(type, []);
      } else {
        setSelectedType(type);
        setShowFriendsList(true);
      }
    };
  };

  const handleUserSelected = (user: UserModel) => {
    setSelectedFriends([...selectedFriends, user]);
  };

  const handleUserDeselected = (user: UserModel) => {
    setSelectedFriends(selectedFriends.filter((item) => item.id !== user.id));
  };

  const handleSubmitInvites = () => {
    setValues(selectedType, selectedFriends);
  };

  return (
    <div>
      {!showFriendsList && (
        <SelectList
          items={visibilityTypeOptions.map((type) => {
            return {
              label:
                visibilityType === type.value &&
                type.value === visibilityTypes.private
                  ? `${type.label} (${invites?.length})`
                  : type.label,
              value: type.value,
              isActive: type.value === selectedType,
              icon:
                type.value !== visibilityTypes.private ? (
                  <BiChevronRight />
                ) : (
                  <BiEdit />
                ),
              onClick: handleTypeSet(type.value),
            };
          })}
        />
      )}

      {showFriendsList && (
        <div>
          <BackButton onClick={() => setShowFriendsList(false)} />
          <FriendList
            list={[
              ...(connections?.friends || []),
              ...(connections?.following || []),
            ]}
            selected={selectedFriends}
            onSelect={handleUserSelected}
            onDeselectUser={handleUserDeselected}
            onSubmit={handleSubmitInvites}
          />
        </div>
      )}
    </div>
  );
};
