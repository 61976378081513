import { UserModel, WebsocketEvent } from "../lib/types";
import logger from "../lib/utils/logger";

const RECONNECT_TIMEOUT = 1000;
let _connectionAttempts = 0;
let _reconnectTimeoutId: any = null;

export let _socket: WebSocket | null = null;

export function onConnect(cb = () => {}) {
  return (event: any) => {
    cb();
  };
}

export function onDisconnect(cb = () => {}) {
  cb();
}

export function onFooEvent(value: any, cb = (value: any) => {}) {
  cb(value);
}

export const getSocket = (
  user: UserModel,
  onConnectedCallback: (socket: WebSocket) => void = (s: WebSocket) => {},
  onDisconnectedCallback: () => void = () => {},
  onMessageCallback: (event: WebsocketEvent) => void = (e: WebsocketEvent) => {}
): WebSocket => {
  const attemptReconnect = (user: UserModel) => {
    const wait = RECONNECT_TIMEOUT * _connectionAttempts;
    logger("socket closed, reconnecting in", wait, "ms");

    if (_reconnectTimeoutId !== null) {
      clearTimeout(_reconnectTimeoutId);
    }

    _reconnectTimeoutId = setTimeout(() => {
      _socket = null;
      getSocket(
        user,
        onConnectedCallback,
        onDisconnectedCallback,
        onMessageCallback
      );
    }, wait);

    _connectionAttempts += 1;
  };

  if (_socket !== null) {
    return _socket;
  }

  const protocol = process.env
    .REACT_APP_API_BASE!.toString()
    .startsWith("https")
    ? "wss"
    : "ws";

  // @ts-ignore
  _socket = new WebSocket(
    `${protocol}://${process.env.REACT_APP_API_HOST}?user_id=${user?.id}`
  );

  _socket.addEventListener("open", (event) => {
    _connectionAttempts = 0;
    onConnectedCallback(_socket!);
  });

  _socket.addEventListener("close", (event) => {
    logger("socket closed", event);
    onDisconnectedCallback();
    attemptReconnect(user);
  });

  _socket.addEventListener("error", (event) => {
    logger("socket error", event);
    onDisconnectedCallback();
    // attemptReconnect(user);
  });

  _socket.addEventListener("message", (event: MessageEvent<any>) => {
    logger("websocket MESSAGE", event);
    console.log("GOT MESSAGE!!!!", event);

    const data = JSON.parse(event.data);
    onMessageCallback(data);
  });

  return _socket;
};
