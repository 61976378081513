import classNames from "classnames";
import { Badge } from "flowbite-react";
import { BiChevronRight } from "react-icons/bi";
import { ReactNode } from "react";

type SelectRowProps = {
  onClick: () => void;
  label: string;
  badge: string | null;
  isFirst: boolean;
  prefix?: ReactNode;
};
export const SelectRow = ({
  onClick,
  label,
  badge,
  isFirst,
  prefix,
}: SelectRowProps) => {
  return (
    <div
      className={classNames(
        "flex flex-row justify-between w-full py-4 border-b",
        { "border-t": isFirst }
      )}
      onClick={onClick}
    >
      <div className={"flex flex-row justify-start items-center"}>
        {prefix && <div className={"mr-2"}>{prefix}</div>}
        {label}
      </div>
      <div className={"flex flex-row items-center"}>
        <div>{badge && <Badge>{badge}</Badge>}</div>
        <div>
          <BiChevronRight />
        </div>
      </div>
    </div>
  );
};
