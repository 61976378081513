import React, { PropsWithChildren } from "react";
import { Button } from "flowbite-react";
import classNames from "classnames";
import { IoClose } from "react-icons/io5";
import Menu from "../../components/Menu";
import CircleButton from "../../components/CircleButton";

export type SubViewProps = PropsWithChildren & {
  navigateButton?: React.ReactNode;
  onClose?: () => void;
  topBar?: React.ReactNode;
  dark?: boolean;
};
export const SubView = ({
  navigateButton,
  onClose,
  children,
  dark = true,
}: SubViewProps) => {
  let bgDivStyle = "absolute top-0 bottom-0 right-0 left-0";
  if (dark) bgDivStyle += " bg-gray-900 bg-opacity-70";
  return (
    <div style={{ zIndex: 9999999 }} className={bgDivStyle}>
      {typeof navigateButton !== "undefined" && (
        <span className={"absolute top-4 left-4"}>{navigateButton}</span>
      )}
      {typeof onClose !== "undefined" && (
        <Button
          size={"xs"}
          color={"white"}
          onClick={onClose}
          className={"absolute top-3 right-0 z-50 "}
        >
          <IoClose fontSize={24} color="white" />
        </Button>
      )}
      <div className="w-full absolute">{children}</div>
    </div>
  );
};
