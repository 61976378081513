import { on } from "../lib/utils/event-bus";
import Rollbar from "rollbar";

/**
 * there has to be a better way to do this
 */

let rollbar: Rollbar | null = null;

on("set-rollbar", (r: Rollbar) => {
  rollbar = r;
});

export const getRollbar = (): Rollbar => {
  return rollbar!;
};
