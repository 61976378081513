import { Button } from "flowbite-react";
import { routes } from "../../config/routes";
import { GrClose } from "react-icons/gr";
import React, { ReactNode } from "react";
import { MessageThreadModel } from "../../lib/types";
import classNames from "classnames";
import { Link } from "react-router-dom";

const ButtonText = ({ text }: { text: string }) => (
  <span className={""}>{text}</span>
);

const TabMenuButton = ({
  text,
  count,
  active,
  last = false,
  onClick,
  href,
}: {
  text: string;
  count?: number;
  active: boolean;
  last?: boolean;
  onClick: () => void;
  href?: string;
}) => {
  const Wrapper = ({
    className,
    children,
  }: {
    className: string;
    children: ReactNode;
  }) =>
    typeof href !== "undefined" ? (
      <Link className={className} to={href}>
        {children}
      </Link>
    ) : (
      <button className={className}>{children}</button>
    );

  const onClickProps = typeof href === "undefined" ? { onClick } : {};
  return (
    <Wrapper
      className={classNames(
        "text-sm rounded-full flex flex-row px-2 py-1 flex-grow items-center justify-center",
        {
          "bg-gradient-button": active,
          "text-white": active,
          "mr-2": !last,
        }
      )}
      {...onClickProps}
    >
      <ButtonText text={text} />
      {typeof count !== "undefined" && count !== 0 && (
        <span className={"ml-2 bg-secondary px-2 rounded-full"}>{count}</span>
      )}
    </Wrapper>
  );
};

export type SubViewTabMenuItem = {
  text: string;
  count?: number;
  active: boolean;
  href?: string;
  onClick?: () => void;
};
export type SubViewTabMenuProps = {
  items: SubViewTabMenuItem[];
};
export default function SubViewTabMenu({ items }: SubViewTabMenuProps) {
  return (
    <div className={"flex flex-row justify-between items-center mb-4"}>
      <div
        className={
          "flex flex-row flex-grow justify-between p-1 bg-primary-light rounded-full w-full"
        }
      >
        {items.map((item, index) => {
          const props: any = {};
          if (typeof item.href !== "undefined") {
            props.href = item.href;
          } else if (typeof item.onClick !== "undefined") {
            props.onClick = item.onClick;
          }
          return (
            <TabMenuButton
              key={item.text}
              text={item.text}
              active={item.active}
              count={item.count}
              last={index === items.length - 1}
              {...props}
            />
          );
        })}
      </div>
    </div>
  );
}
