import { PropsWithChildren } from "react";
import { bgImage } from "../config/theme";

export default function SplashScreen({ children }: PropsWithChildren) {
  return (
    <div
      className={"fixed top-0 left-0 w-full h-full bg-primary"}
      style={{
        height: "100vh",
        // backgroundImage: `url("${bgImage}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {children}
    </div>
  );
}
