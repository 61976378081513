import { AllConnections, UserModel } from "../../../lib/types";
import FriendUser from "../../../features/user/FriendUser";

export type FriendsTabProps = {
  users: UserModel[];
  allConnections: AllConnections;
};
export const FriendsTab = ({ users, allConnections }: FriendsTabProps) => {
  return (
    <div className="mt-2">
      {users.map((user) => {
        return (
          <div className={"mb-4"} key={`friend-${user.id}`}>
            <FriendUser
              user={user}
              isMutual={allConnections.friends.some((u) => u.id === user.id)}
            />
          </div>
        );
      })}
    </div>
  );
};
