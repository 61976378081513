import { useNavigate } from "react-router-dom";
import { routes } from "../config/routes";
import { CSSProperties, useEffect } from "react";
import { auth } from "../config/firebase";

import { bgImage } from "../config/theme";
import { useAppSelector } from "../state/hooks";
import LoadingSpinner from "../components/LoadingSpinner";

const style: CSSProperties = {
  height: "100vh",
  backgroundImage: `url("${bgImage}")`,
  backgroundPosition: "center",
  backgroundSize: "cover",
};

export default function RootView() {
  const navigate = useNavigate();
  const apiUser = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (auth.currentUser) {
      navigate(routes.mapRoot);
    } else {
      navigate(routes.login);
    }
  }, [apiUser]);
  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full"
      style={style}
    >
      <LoadingSpinner />
    </div>
  );
}
