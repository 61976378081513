// import { useEffect, useState } from "react";
// import {
//   canInstall,
//   getMobileOperatingSystem,
//   isAppInstalled,
// } from "./lib/utils/pwa";
// import { off, on } from "./lib/utils/event-bus";
// import { setInstallPrompt, setIsInstalled } from "./features/app-slice";
// import { useAppDispatch, useAppSelector } from "./state/hooks";
// import Install from "./views/Install";
import App from "./App";
import Permissions from "./views/Permissions";
// import SplashScreenLoading from "./components/SplashScreenLoading";

/**
 * @todo figure this out later
 */
export default function AppInit() {
  // const dispatch = useAppDispatch();
  //
  // const isInstalled = useAppSelector((state) => state.app.isInstalled);
  // const [os] = useState<"Android" | "iOS" | "Windows Phone" | "unknown">(
  //   getMobileOperatingSystem()
  // );
  //
  // const handleInstallPrompt = (prompt: any) => {
  //   console.log("install prompt prompted@@!!!!", prompt);
  //   dispatch(setInstallPrompt(prompt));
  // };
  //
  // useEffect(() => {
  //   switch (os) {
  //     case "Android":
  //       console.log("Android");
  //
  //       // @ts-ignore
  //       if (window.INSTALL_PROMPT === null) {
  //         on("install-prompt", handleInstallPrompt);
  //       }
  //
  //       canInstall().then((installable) => {
  //         if (installable) {
  //         } else {
  //           dispatch(setInstallPrompt(null));
  //         }
  //       });
  //
  //       break;
  //     case "iOS":
  //       console.log("iOS");
  //       dispatch(setInstallPrompt(null));
  //       break;
  //     default:
  //       console.log("window phone or unknown");
  //       dispatch(setInstallPrompt(null));
  //       break;
  //   }
  //
  //   return () => off("install-prompt", handleInstallPrompt);
  // }, []);
  //
  // // check if the app is installed
  // useEffect(() => {
  //   if (isInstalled === null) {
  //     document.body.style.color = "red";
  //     isAppInstalled().then((installed) => {
  //       dispatch(setIsInstalled(installed));
  //     });
  //   }
  // }, [isInstalled]);

  // if (isInstalled === true) {
  return <Permissions />;
  // }

  // if (isInstalled === false) {
  //   return <Install />;
  // }
  //
  // return <SplashScreenLoading />;
}
