import Layout from "../components/Layout";
import { Button, FileInput, Label, Select, TextInput } from "flowbite-react";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import PhotoStorageService from "../lib/services/photo-storage-service";
import { fetchUser, finalizeUser, usernameInUse } from "../lib/api/users";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { useNavigate } from "react-router-dom";
import { setApiUser } from "../features/user/user-slice";
import { routes } from "../config/routes";
import { fetchGenders } from "../lib/api/genders";
import { GenderModel, InviteModel } from "../lib/types";
import { setGenders } from "../features/genders/genders-slice";
import logger from "../lib/utils/logger";
import LoadingSpinner from "../components/LoadingSpinner";
import { useRollbar } from "@rollbar/react";
import inviteService from "../lib/services/invite-service";
import {
  acceptInvite,
  fetchInviteForUser,
  inviteCodeIsValid,
} from "../lib/api/invites";
import { motion, AnimatePresence } from "framer-motion";
import SplashScreen from "../components/SplashScreen";
import { auth } from "../config/firebase";

const FieldError = ({ message }: { message: string | null | undefined }) => {
  if (!message) {
    return null;
  }

  return (
    <div className={"text-red-500 italic text-sm mt-1 font-bold"}>
      {message}
    </div>
  );
};

const fileInputTheme = {
  field: {
    input: {
      colors: {
        gray: "bg-gray-50 border-gray-300 text-gray-100 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        info: "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        failure:
          "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
        warning:
          "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
        success:
          "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500",
      },
    },
  },
};

export default function SignupView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const rollbar = useRollbar();
  const appUser = useAppSelector((state) => state.user.user);
  const genders = useAppSelector((state) => state.genders.genders);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const birthDateRef = useRef<HTMLInputElement>(null);
  const [genderId, setGenderId] = useState<number | null>(null);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [inviteCode, setInviteCode] = useState<string>(
    inviteService.code() || ""
  );
  const [hasCheckedForInvite, setHasCheckedForInvite] =
    useState<boolean>(false);
  const [acceptedInvite, setAcceptedInvite] = useState<InviteModel | null>(
    null
  );
  const [isValidatingInviteCode, setIsValidatingInviteCode] =
    useState<boolean>(false);

  useEffect(() => {
    logger(appUser);
    if (!appUser?.id) {
      logger("returning!!");
      return;
    }

    Promise.all([fetchGenders(), fetchInviteForUser()]).then(
      ([genders, invite]: [GenderModel[], InviteModel | null]) => {
        dispatch(setGenders(genders));
        setAcceptedInvite(invite);
        setHasCheckedForInvite(true);
      }
    );
  }, [appUser?.id]);

  const handleFileSelected = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    // @ts-ignore
    const files = Array.from(e.target.files);
    setPhotoFile(files[0]);
  };

  const handleValidateInviteCode = async () => {
    setIsValidatingInviteCode(true);

    if (!(await inviteCodeIsValid(inviteCode))) {
      setErrors({ inviteCode: "Invite code is invalid" });
    } else {
      const invite = await acceptInvite(inviteCode);
      setAcceptedInvite(invite);
    }

    setIsValidatingInviteCode(false);
  };

  const handleSubmit = async () => {
    let hasError = false;
    const errors: { [field: string]: string } = {};
    setErrors({});

    let photoUrls = null;

    // if (!inviteCode.trim()) {
    //   errors.inviteCode = "Invite code is required";
    //   hasError = true;
    // } else if (!(await inviteCodeIsValid(inviteCode))) {
    //   errors.inviteCode = "Invite code is invalid";
    // } else {
    //   inviteService.manuallyStoreCode(inviteCode);
    // }

    // first name
    if (!firstNameRef.current!.value.trim()) {
      errors.firstName = "First name is required";
      hasError = true;
    }

    // photo
    if (photoFile === null) {
      errors.photo = "A photo is required";
      hasError = true;
    } else if (photoFile.size > 52428800) {
      errors.photo = "Photo must be less than 50MB";
      hasError = true;
    }

    // username
    if (!usernameRef.current!.value.trim()) {
      errors.username = "Username is required";
      hasError = true;
    } else if (await usernameInUse(appUser!, usernameRef.current!.value)) {
      errors.username = "Username is already taken";
      hasError = true;
    }

    // gender
    if (!genderId) {
      errors.genderId = "Gender is required";
      hasError = true;
    }

    // birthdate
    if (!birthDateRef.current!.value.trim()) {
      errors.birthDate = "Birth date is required";
      hasError = true;
    } else if (new Date(birthDateRef.current!.value) > new Date()) {
      errors.birthDate = "Birth date must be in the past";
      hasError = true;
    }

    if (hasError) {
      setErrors(errors);
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(true);

    try {
      photoUrls = await PhotoStorageService.storePhoto(photoFile!);
    } catch (err) {
      rollbar.error("Sign-up - Photo Upload Error", err as Error, {
        firebase_uid: appUser?.firebase_uid,
        file_mime_type: photoFile?.type,
        err_message: (err as Error).message,
        err_cause: (err as Error).cause,
        err_stack: (err as Error).stack,
        error: (err as Error).toString(),
        error_json: JSON.stringify(err as Error),
      });
      setErrors({ photo: "Error uploading photo, please try again" });
      setIsSubmitting(false);
      return;
    }

    try {
      await finalizeUser(
        firstNameRef.current!.value,
        usernameRef.current!.value,
        birthDateRef.current!.value,
        photoUrls.storageUrl,
        genderId!
      );
    } catch (err) {
      rollbar.error("Sign-up - Finalize Error", err as Error, {
        firebase_uid: appUser?.firebase_uid,
        firstName: firstNameRef.current!.value,
        username: usernameRef.current!.value,
        birthDate: birthDateRef.current!.value,
        photoUrls,
        genderId,
      });
      setErrors({ submit: "Whoops, we messed up :/" });
      setIsSubmitting(false);
      return;
    }

    const user = await fetchUser();

    dispatch(setApiUser(user));
    navigate(routes.onboarding);
  };

  const hasValidatedInviteCode = hasCheckedForInvite && acceptedInvite !== null;

  if (!hasCheckedForInvite) {
    return <SplashScreen />;
  }

  return (
    <Layout header={"Sign Up"} hideMenu hideConnectionStatus fullHeight>
      <div className={"p-4 bg-gradient-permissions h-full text-gray-100"}>
        <AnimatePresence>
          {!hasValidatedInviteCode && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              transition={{ duration: 0.2 }}
              exit={{ height: 0 }}
              className="max-w-md mb-2"
            >
              <p className={"mb-4"}>
                TWIBS is an invite-only community. To get started, please enter
                your invitation code. If you don't have a code, you'll need to
                request one from a friend.
              </p>
              <div className="mb-2 block">
                <Label
                  htmlFor="invite_code"
                  value="Invite Code"
                  className={"font-bold text-gray-200"}
                />
              </div>
              <TextInput
                id="invite_code"
                placeholder="Invite Code"
                required
                defaultValue={inviteService.code() || ""}
                onInput={(e: FormEvent<HTMLInputElement>) => {
                  setInviteCode(e.currentTarget.value);
                }}
              />
              <FieldError message={errors.inviteCode} />
              <Button
                className={"mt-4 w-full"}
                onClick={handleValidateInviteCode}
                isProcessing={isValidatingInviteCode}
              >
                Submit
              </Button>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {hasValidatedInviteCode && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              transition={{ duration: 0.2 }}
              exit={{ height: 0 }}
            >
              <div className={"text-lg mb-2 font-bold"}>
                Create your Account
              </div>
              <div className="max-w-md mb-2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="first_name"
                    value="First Name"
                    className={"font-bold text-gray-200"}
                  />
                </div>
                <TextInput
                  id="first_name"
                  placeholder=""
                  required
                  ref={firstNameRef}
                />
                <FieldError message={errors.firstName} />
              </div>

              <div className="max-w-md mb-2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="username"
                    value="Username"
                    className={"font-bold text-gray-200"}
                  />
                </div>
                <TextInput
                  addon="@"
                  id="username"
                  placeholder=""
                  required
                  ref={usernameRef}
                />
                <FieldError message={errors.username} />
              </div>

              <div className="max-w-md mb-2">
                <div className="mb-2 block">
                  <Label
                    htmlFor="birth_date"
                    value="Birth Date"
                    className={"font-bold text-gray-200"}
                  />
                </div>
                <TextInput
                  id="birth_date"
                  placeholder='"cold_cheetos_69"'
                  type="date"
                  required
                  ref={birthDateRef}
                />
                <FieldError message={errors.birthDate} />
              </div>

              <div className="max-w-md mb-2" id="select">
                <div className="mb-2 block">
                  <Label
                    htmlFor="gender"
                    value="Gender"
                    className={"font-bold text-gray-200"}
                  />
                </div>
                <Select
                  id="gender"
                  required
                  // @ts-ignore
                  onChange={(e) => setGenderId(e.target.value)}
                >
                  <option>-- Select --</option>
                  {genders.map((gender) => {
                    return (
                      <option key={`gender-${gender.id}`} value={gender.id}>
                        {gender.name}
                      </option>
                    );
                  })}
                </Select>
                <FieldError message={errors.genderId} />
              </div>

              <div className="max-w-md" id="fileUpload">
                <div className="mb-2 block">
                  <Label
                    htmlFor="file"
                    value="Profile Photo"
                    className={"font-bold text-gray-200"}
                  />
                </div>
                <FileInput
                  onChange={handleFileSelected}
                  id="file"
                  className={"text-gray-100"}
                />
                <FieldError message={errors.photo} />
              </div>

              <div className={"max-w-md mt-10"}>
                {isSubmitting && (
                  <div className={"flex flex-row w-full justify-center"}>
                    <LoadingSpinner />
                  </div>
                )}
                {!isSubmitting && (
                  <Button
                    className={"w-full text-primary font-bold"}
                    color={"warning"}
                    onClick={handleSubmit}
                  >
                    Submit!
                  </Button>
                )}
                <FieldError message={errors.submit} />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div>
          <Button
            onClick={() => {
              auth.signOut();
            }}
          >
            Go Back
          </Button>
        </div>
      </div>
    </Layout>
  );
}
