import { SubView } from "../SubView";
import { SubViewHeader } from "../SubViewHeader";
import { routes } from "../../../config/routes";
import { generatePath, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { Fragment, useEffect, useState } from "react";
import logger from "../../../lib/utils/logger";
import {
  disableTwib,
  fetchAllTwibsCreatedByUser,
} from "../../../lib/api/twibs";
import { setUserTwibs } from "../../../features/user/user-slice";
import { TwibModel } from "../../../lib/types";
import {
  iconForVisibilityType,
  paymentTypeDetails,
  twibStatus,
  visibilityByType,
} from "../../../lib/dictionary";
import { DateTime } from "luxon";
import { Tooltip } from "react-tooltip";
import { PhotoUrl } from "../../../lib/utils/photo-url";
import { twibDetailDate, twibDetailTime } from "../../../lib/utils/date";
import { BsTrash } from "react-icons/bs";
import {
  setDialogMessage,
  setTwibToDelete,
} from "../../../features/config-slice";
import { BiSolidEdit } from "react-icons/bi";

const expiredTwibsOnly = (twib: TwibModel): boolean => {
  if (twib.twib_date === null) {
    return false;
  }

  const twibDate = DateTime.fromISO(twib.twib_date, { zone: twib.timezone });

  return DateTime.now() > twibDate;
};

const activeTwibsOnly = (twib: TwibModel): boolean => {
  return !expiredTwibsOnly(twib) && twib.status === twibStatus.active;
};

const inactiveTwibsOnly = (twib: TwibModel): boolean => {
  return !expiredTwibsOnly(twib) && twib.status === twibStatus.inactive;
};

export default function ProfileSubView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const twibs = useAppSelector((state) => state.user.twibs);
  const [hasFetchedTwibs, setHasFetchedTwibs] = useState<boolean>(false);

  useEffect(() => {
    logger(
      "ProfileView - user changed",
      user !== null ? user.profile_photo_url : "n/a"
    );
  }, [user]);

  const refetch = () => {
    fetchAllTwibsCreatedByUser().then((twibs) => {
      dispatch(setUserTwibs(twibs));
      setHasFetchedTwibs(true);
    });
  };

  useEffect(() => {
    if (user?.id && !hasFetchedTwibs) {
      refetch();
    }
  }, [dispatch, user, hasFetchedTwibs]);

  const activeTwibsOnly = (twib: TwibModel): boolean => {
    return !expiredTwibsOnly(twib) && twib.status === twibStatus.active;
  };

  const activeTwibs = twibs.filter(activeTwibsOnly);

  return (
    <SubView onClose={() => navigate(routes.mapRoot)}>
      <SubViewHeader title="My Profile" height={60}>
        <BiSolidEdit
          onClick={() => navigate(routes.editProfile)}
          style={{ position: "absolute", top: 20, left: 115 }}
          fontSize={22}
        />
        <div className={"bg-primary rounded-xl p-2 mt-8"}>
          <div
            className={"bg-white rounded text-primary"}
            style={{
              maxHeight: "calc(100vh - 120px)",
              overflow: "scroll",
            }}
          >
            <div className="justify-center flex flex-row">
              <img
                src={PhotoUrl.user(user).thumb}
                alt={PhotoUrl.user(user).thumb}
                className={"rounded-full mt-4"}
                style={{ height: "100px", width: "100px" }}
              />
            </div>
            <div className="justify-center flex flex-col items-center">
              <div
                style={{
                  fontWeight: 600,
                }}
                className="text-l text-primary mt-1"
              >
                {user?.first_name}
              </div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "grey",
                }}
                className="text-l text-primary"
              >
                @{user?.username}
              </div>
            </div>
            <div>
              {!activeTwibs.length ? (
                <h2 className="mt-8 text-primary text-xl ml-16 mr-16 mb-8 text-center">
                  Your Twibs will show up here
                </h2>
              ) : (
                <>
                  <h2
                    style={{ paddingLeft: "24px", fontSize: 16 }}
                    className="mt-2 text-primary text-xl mb-1 text-start"
                  >
                    Your Twibs:
                  </h2>
                  {activeTwibs.map((t, i) => (
                    <Fragment key={t.id}>
                      <ProfileSubViewTwibDetails refetch={refetch} twib={t} />
                      {i < activeTwibs.length - 1 && (
                        <div
                          style={{
                            margin: "0px 48px 10px",
                            background: "lightgrey",
                            height: "1px",
                          }}
                        />
                      )}
                    </Fragment>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </SubViewHeader>
    </SubView>
  );
}

export const ProfileSubViewTwibDetails = ({
  twib,
  refetch,
}: {
  twib: TwibModel;
  refetch: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dialogResponse = useAppSelector((state) => state.config.dialogResponse);
  const twibToDelete = useAppSelector((state) => state.config.twibToDelete);

  const handleShowMessages = () => {
    navigate(generatePath(routes.map, { view: "messages", id: twib?.chat.id }));
  };

  const coverImage: string | null = twib?.image_uri || null;

  const handleTrash = () => {
    const message = `Are you sure you want to delete your Twib at ${twib.location.name}? This will also delete the chat for everyone`;
    dispatch(setDialogMessage(message));
    dispatch(setTwibToDelete(twib));
  };

  useEffect(() => {
    console.log({ dialogResponse, twib });
    if (dialogResponse && twib.id === twibToDelete?.id) {
      disableTwib(twib!).then(() => {
        refetch();
      });
    }
  }, [dialogResponse]);

  return (
    <div className={"flex-grow bg-primary-light rounded-2xl mt-1"}>
      <div style={{ padding: "12px 24px 24px 24px" }}>
        <div className={"flex flex-row justify-between font-bold mb-3"}>
          <div className={"flex flex-row items-center"}>
            <img
              src={PhotoUrl.location(twib.location)}
              alt="profile avatar"
              className={"rounded-full w-9 h-9 border border-lime-300"}
            />
            <div
              style={{
                fontSize: 14,
                maxWidth: "55%",
                marginLeft: "8px",
              }}
            >
              {twib.location.name}
            </div>
            <div
              style={{
                marginLeft: "8px",
              }}
            >
              <div
                data-tooltip-id="vistype"
                data-tooltip-content={visibilityByType(twib.visibility_type)}
              >
                {iconForVisibilityType(twib.visibility_type)}
              </div>
              <Tooltip place="bottom" id="vistype" />
            </div>
          </div>
          <div
            className={"flex flex-row justify-start items-center"}
            style={{
              fontSize: 16,
            }}
          >
            <span className={"mr-2"}>
              {paymentTypeDetails[twib.type.name].icon}
            </span>
            <span
              style={{
                fontSize: 14,
              }}
            >
              {twib.type.name}
            </span>
          </div>
        </div>
        {coverImage && (
          <img
            style={{ margin: "8px 0px" }}
            className={"w-full object-cover"}
            src={coverImage}
            alt={coverImage}
          />
        )}
        {twib.description && (
          <div
            style={{ background: "#2626260D", padding: "12px 8px" }}
            className={"flex flex-row justify-around"}
          >
            {twib.description}
          </div>
        )}
        <div
          style={{ background: "#2626260D", padding: "12px 8px" }}
          className={"flex flex-row justify-around items-center mt-2"}
        >
          <div className={"flex flex-row justify-start items-center mr-2"}>
            🗓&nbsp;&nbsp;
            {twibDetailDate(twib)}
          </div>
          <div
            style={{
              height: "100%",
              padding: "10px 0px",
              width: "1px",
              background: "#2626264D",
            }}
          />
          <div className={"flex flex-row justify-start items-center"}>
            ⏰&nbsp;&nbsp;
            {twibDetailTime(twib)}
            &nbsp;
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "50px",
              height: "100%",
              padding: "10px 40px",
              margin: "12px 6px 0px 0px",
              background: "transparent",
              borderRadius: "24px",
              boxSizing: "border-box",
              border: "1px solid #DDD",
              fontSize: "16px",
              fontWeight: 500,
              justifyContent: "center",
            }}
            onClick={handleTrash}
          >
            <button>
              <BsTrash color="gray" />
            </button>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              padding: "6px 8px",
              margin: "12px 0px 0px 0px",
              background: "#AFBBF2",
              border: "1px solid #AFBBF2",
              borderRadius: "24px",
              justifyContent: "center",
              color: "#262626",
              fontSize: "16px",
              fontWeight: 500,
            }}
            onClick={handleShowMessages}
          >
            <button>View Chat</button>
          </div>
        </div>
      </div>
    </div>
  );
};
