import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import Menu from "./Menu";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { UserModel } from "../lib/types";
import { messaging } from "../config/firebase";
import { onMessage } from "firebase/messaging";
import { Toast } from "flowbite-react";
import { HiFire } from "react-icons/hi";
import classNames from "classnames";
import { setToast } from "../features/config-slice";
import WebsocketConnectionStatus from "./WebsocketConnectionStatus";
import { bgImage } from "../config/theme";
import ConfirmationDialog from "../views/sub-views/profile/ConfirmationDialog";
import { MAX_Z_INDEX } from "../lib/dictionary";

const menuStyle: CSSProperties = {
  position: "fixed",
  bottom: 6,
  left: 6,
  zIndex: "102",
  right: 6,
};

export type LayoutProps = {
  header?: ReactNode;
  hideMenu: boolean;
  hideConnectionStatus: boolean;
  fullHeight: boolean;
  right?: ReactNode;
} & PropsWithChildren;
export default function Layout({
  header,
  hideMenu,
  hideConnectionStatus,
  fullHeight,
  children,
  right,
}: LayoutProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector<UserModel | null>((state) => state.user.user);
  const toastMessage = useAppSelector((state) => state.config.toastMessage);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [pushMessage, setPushMessage] = useState<string>("");

  useEffect(() => {
    if (typeof header === "string") {
      document.title = `TWIBS | ${header}`;
    }
  }, []);

  useEffect(() => {
    if (toastMessage !== null) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        dispatch(setToast(null));
      }, toastMessage?.timeout || 5000);
    }
  }, [toastMessage]);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      setPushMessage(payload.notification?.body || "no notification body");

      dispatch(setToast({ body: payload.notification?.body || "<no body>" }));

      setTimeout(() => {
        dispatch(setToast(null));
      }, 10000);
    });
  }, [dispatch]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",
        }}
        className="flex flex-col justify-between h-full"
      >
        <div
          className={classNames(
            "flex flex-col justify-start flex-grow shrink-0 overflow-y-scroll",
            {
              "pt-2": !fullHeight,
            }
          )}
        >
          {children}
        </div>

        {showToast && toastMessage !== null && (
          <div
            className={"flex flex-row justify-center items-center w-full"}
            style={{
              position: "fixed",
              bottom: 85,
              zIndex: MAX_Z_INDEX,
            }}
          >
            <Toast className={"rounded-full flex-shrink"}>
              <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
                <HiFire className="h-5 w-5" />
              </div>
              <div className="ml-3 text-sm font-normal">
                {toastMessage.body}
              </div>
              <Toast.Toggle />
            </Toast>
          </div>
        )}
        <ConfirmationDialog />
        {!hideMenu && (
          <div style={menuStyle} className="p-2">
            {user?.id && <Menu user={user} />}
          </div>
        )}
      </div>
    </>
  );
}

Layout.defaultProps = {
  hideMenu: false,
  hideConnectionStatus: false,
  fullHeight: false,
};
