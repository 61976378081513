import iOSSafari from "../assets/images/install-ios-safari.jpg";
import { ReactNode, useState } from "react";
import classNames from "classnames";
import { DropdownButton } from "./DropdownButton";
import { Button } from "flowbite-react";
import { IoClose } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";

const guessCurrentBrowser = (): Browser => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Chrome")) {
    return "Chrome";
  }

  if (userAgent.includes("Safari")) {
    return "Safari";
  }

  return "Other";
};

const guessCurrentOS = (): OS => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
    return "iOS";
  }

  if (userAgent.includes("Android")) {
    return "Android";
  }

  return "Computer";
};

const Toggle = ({
  leftLabel,
  rightLabel,
  value,
  onChange,
}: {
  leftLabel: OS;
  rightLabel: OS;
  value: OS;
  onChange: (v: OS) => void;
}) => {
  return (
    <div className={"flex flex-row justify-center items-center"}>
      <div
        className={classNames("text-lg px-3", {
          "font-bold": leftLabel === value,
        })}
        onClick={() => onChange(leftLabel)}
      >
        {leftLabel}
      </div>
      <div className={"mx-4"}>
        {leftLabel === value ? (
          <div>👈</div>
        ) : (
          <div onClick={() => onChange(leftLabel)}>👉</div>
        )}
      </div>
      <div
        className={classNames("text-lg px-3", {
          "font-bold": rightLabel === value,
        })}
        onClick={() => onChange(rightLabel)}
      >
        {rightLabel}
      </div>
    </div>
  );
};

type OS = "iOS" | "Android" | "Computer";
type Browser = "Safari" | "Chrome" | "Other";

const Instructions = ({
  title,
  steps,
}: {
  title: string;
  steps: ReactNode[];
}) => {
  return (
    <div>
      <div className={"text-xl font-bold mb-2"}>{title}</div>
      <div className={"flex flex-col justify-start items-start"}>
        {steps.map((step, index) => {
          return (
            <div
              key={index}
              className={"flex flex-row justify-start items-start"}
            >
              <div className={"mr-2"}>{index + 1}.</div>
              <div>{step}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AndroidChrome = () => {
  return (
    <Instructions
      title={"Android / Other"}
      steps={[
        <div>Tap the three vertical dots in the top right</div>,
        <div>Select "Install App"</div>,
        <div>Select a name for the shortcut</div>,
        <div>Re-open the app from the home screen icon</div>,
      ]}
    />
  );
};

const AndroidSafari = () => {
  return (
    <div>
      <div>Somehow I don't think so 😅</div>
    </div>
  );
};

const AndroidOther = () => {
  return (
    <Instructions
      title={"Android / Other"}
      steps={[<div>🤷</div>, <div>Your platform may not be supported</div>]}
    />
  );
};

const IOSSafari = () => {
  return (
    <Instructions
      title={"iOS / Safari"}
      steps={[
        <div>
          <div>Tap the "Share" button at the bottom of the screen</div>
          <div>
            <img src={iOSSafari} alt="Install instructions for iOS Safari" />
          </div>
        </div>,
        <div>Select the "Add to Home Screen" option</div>,
        <div>Accept the default options</div>,
        <div>Re-open the app from the home screen icon</div>,
      ]}
    />
  );
};

const IOSChrome = () => {
  return <Instructions title={"iOS / Chrome"} steps={[]} />;
};

const IOSOther = () => {
  return (
    <Instructions
      title={"iOS / Other"}
      steps={[<div>🤷</div>, <div>Your platform may not be supported</div>]}
    />
  );
};

const ComputerChrome = () => {
  return <Instructions title={"Computer / Chrome"} steps={[]} />;
};

const ComputerOther = () => {
  return (
    <Instructions
      title={"Computer / Other"}
      steps={[<div>🤷</div>, <div>Your platform may not be supported</div>]}
    />
  );
};

const ComputerSafari = () => {
  return (
    <Instructions
      title={"Computer / Other"}
      steps={[<div>🤷</div>, <div>Your platform may not be supported</div>]}
    />
  );
};

export default function InstallInstructions({
  onClose,
}: {
  onClose: () => void;
}) {
  const [os, setOs] = useState<OS>(guessCurrentOS());
  const [browser, setBrowser] = useState<Browser>(guessCurrentBrowser());

  const instructions: { [osKey in OS]: { [brKey in Browser]: ReactNode } } = {
    iOS: {
      Safari: <IOSSafari />,
      Chrome: <IOSChrome />,
      Other: <IOSOther />,
    },
    Android: {
      Chrome: <AndroidChrome />,
      Other: <AndroidOther />,
      Safari: <AndroidSafari />,
    },
    Computer: {
      Chrome: <ComputerChrome />,
      Other: <ComputerOther />,
      Safari: <ComputerSafari />,
    },
  };

  return (
    <div className={"text-gray-800"}>
      <Button
        className={"absolute top-4 right-4"}
        color={"no-color"}
        onClick={onClose}
      >
        <AiOutlineClose />
      </Button>
      <div className={"text-2xl font-bold"}>Install instructions</div>

      <div className={"flex flex-row justify-around mt-4"}>
        <div className={"text-center"}>
          <div className={"text-lg font-bold mb-1"}>I'm using</div>
          <div>
            <DropdownButton
              options={["Safari", "Chrome", "Other"]}
              onSelectOption={(val) => setBrowser(val as Browser)}
              selectedOption={browser}
            />
          </div>
        </div>

        <div className={"text-center"}>
          <div className={"text-lg font-bold mb-1"}>on</div>
          <div>
            <DropdownButton
              options={["iOS", "Android", "Computer"]}
              onSelectOption={(val) => setOs(val as OS)}
              selectedOption={os}
            />
          </div>
        </div>
      </div>

      <div className={"mt-6"}>{instructions[os][browser]}</div>
    </div>
  );
}
