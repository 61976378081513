import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { generatePath, useNavigate } from "react-router-dom";
import { fetchAllConnections } from "../../../lib/api/friends";
import { setAllConnections } from "../../../features/user/user-slice";
import { UserModel } from "../../../lib/types";
import { routes } from "../../../config/routes";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { FriendsTab } from "./FriendsTab";
import { FollowersTab } from "./FollowersTab";
import { SearchTab } from "./SearchTab";
import { SubView } from "../SubView";
import SubViewTabMenu from "../SubViewTabMenu";
import { createInvite } from "../../../lib/api/invites";
import { Button } from "flowbite-react";
import { FiShare2 } from "react-icons/fi";
import { SubViewHeader } from "../SubViewHeader";
import { emit } from "process";

export default function PeopleSubView() {
  const tabs = ["friends", "connections", "search"];
  const { id: tab } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const allConnections = useAppSelector((state) => state.user.connections);
  const [isInitiallyLoading, setIsInitiallyLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user?.id && isInitiallyLoading) {
      fetchAllConnections()
        .then((connections) => {
          dispatch(setAllConnections(connections));
        })
        .finally(() => {
          setIsInitiallyLoading(false);
        });
    }
  }, [user, isInitiallyLoading, dispatch]);

  const friends = Object.values(
    [...allConnections.friends, ...allConnections.following].reduce(
      (all: { [id: number]: UserModel }, u: UserModel) => {
        all[u.id] = u;
        return all;
      },
      {}
    )
  );

  friends.sort((a: UserModel, b: UserModel) => {
    if (a.first_name === b.first_name) {
      return a.username > b.username ? 1 : -1;
    }

    return a.first_name > b.first_name ? 1 : -1;
  });

  const activeTab = tab || "friends";

  // changes the url param when clicking on tabs
  // const handleActiveTabChange = (tab: number) => {
  //   navigate(generatePath(routes.people, { tab: tabs[tab] }));
  // };

  const menuItems = [
    {
      text: "Friends",
      active: activeTab === "friends",
      href: generatePath(routes.people, { tab: "friends" }),
    },
    {
      text: "Added You",
      active: activeTab === "connections",
      href: generatePath(routes.people, { tab: "connections" }),
    },
    {
      text: "Search",
      active: activeTab === "search",
      href: generatePath(routes.people, { tab: "search" }),
    },
  ];

  let inviteButton = null;

  if (typeof navigator.share === "function" || 1) {
    const handleShare = async () => {
      try {
        const invite = await createInvite();
        await navigator.share({
          title: "TWIBS Invitation",
          text: `Enter Invite Code "${invite.invite_code}" at signup`,
          url: `https://app.twibs.io/#/?invite=${invite.invite_code}`,
        });
      } catch (err) {
        console.error(err);
      }
    };

    inviteButton = (
      <Button
        style={{
          position: "absolute",
          top: 15,
          left: 100,
        }}
        color={"none"}
        className={"bg-primary-light mb-4"}
        size={"xs"}
        onClick={handleShare}
      >
        <FiShare2 className={"mr-2"} />
        Invite Friends
      </Button>
    );
  }

  return (
    <SubView onClose={() => navigate(routes.mapRoot)}>
      <SubViewHeader title="Friends" height={112}>
        {isInitiallyLoading && (
          <div className={"h-full flex flex-col justify-center items-center"}>
            <LoadingSpinner size="xl" />
          </div>
        )}
        {inviteButton}
        {!isInitiallyLoading && (
          <>
            <div style={{ height: 16 }} />
            <SubViewTabMenu items={menuItems} />
            <div
              className={"overflow-y-scroll"}
              style={{ maxHeight: "calc(100vh - 120px)" }}
            >
              {activeTab === "friends" && (
                <FriendsTab users={friends} allConnections={allConnections} />
              )}
              {activeTab === "connections" && (
                <FollowersTab users={allConnections.followers} />
              )}
              {activeTab === "search" && <SearchTab />}
            </div>
          </>
        )}
      </SubViewHeader>
    </SubView>
  );
}
