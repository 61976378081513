import { routes } from "../../config/routes";
import { SubViewHeader } from "./SubViewHeader";
import LoadingSpinner from "../../components/LoadingSpinner";
import SubViewTabMenu from "./SubViewTabMenu";
import { FriendsTab } from "./people/FriendsTab";
import { FollowersTab } from "./people/FollowersTab";
import { SearchTab } from "./people/SearchTab";
import { SubView } from "./SubView";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { UserPreferencesModel } from "../../lib/types";
import {
  deleteUser,
  fetchPreferences,
  updatePreferences,
} from "../../lib/api/users";
import { setToast } from "../../features/config-slice";
import { setApiUser, setUserAuth } from "../../features/user/user-slice";
import { auth } from "../../config/firebase";
import { Button, ToggleSwitch } from "flowbite-react";
import classNames from "classnames";

const SectionTitle = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => (
  <h2 className={classNames("text-primary text-2xl mb-4", className || "")}>
    {text}
  </h2>
);

export default function SettingsSubView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apiUser = useAppSelector((state) => state.user.user);
  const [preferences, setPreferences] = useState<UserPreferencesModel | null>(
    null
  );
  const [isConfirmingDelete, setIsConfirmingDelete] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isInitiallyLoading, setIsInitiallyLoading] = useState<boolean>(true);

  useEffect(() => {
    if (apiUser?.id && preferences === null) {
      fetchPreferences()
        .then(setPreferences)
        .then(() => {
          setIsInitiallyLoading(false);
        });
    }
  }, [apiUser, preferences]);

  const handleSave = async () => {
    await updatePreferences(preferences!);
    dispatch(setToast({ body: "Saved!" }));
  };

  const handleStartDelete = () => {
    setIsConfirmingDelete(true);
  };

  const handleDelete = async () => {
    await deleteUser();
    dispatch(setApiUser(null));
    dispatch(setUserAuth(null));
    auth.signOut();
    setIsConfirmingDelete(false);
  };

  const handleCancelDelete = () => {
    setIsConfirmingDelete(false);
    setIsDeleting(false);
  };

  return (
    <div>
      <SubView onClose={() => navigate(routes.mapRoot)}>
        <SubViewHeader title="Settings" height={60}>
          {isInitiallyLoading && (
            <div className={"h-full flex flex-col justify-center items-center"}>
              <LoadingSpinner size="xl" />
            </div>
          )}
          {!isInitiallyLoading && preferences !== null && (
            <div className={"mt-10 bg-primary rounded-xl p-2"}>
              <section className={"flex flex-col mb-4 bg-white p-2 rounded"}>
                <SectionTitle text={"Notifications"} />
                <div className={"mb-4"}>
                  <ToggleSwitch
                    color={"success"}
                    label="Notifications Enabled"
                    checked={preferences.all_notifications_enabled}
                    onChange={() => {
                      setPreferences({
                        ...preferences,
                        all_notifications_enabled:
                          !preferences.all_notifications_enabled,
                      });
                      handleSave();
                    }}
                  />
                </div>
              </section>

              <section className={""}>
                <div className={"flex flex-row justify-between items-end"}>
                  {!isConfirmingDelete && (
                    <div
                      style={{
                        minInlineSize: "48%",
                      }}
                      className={"mr-1"}
                    >
                      <Button
                        style={{
                          flex: "1",
                        }}
                        className="w-full"
                        color={"warning"}
                        onClick={() => auth.signOut()}
                      >
                        Sign Out
                      </Button>
                    </div>
                  )}
                  <div className={"flex flex-row justify-between w-full"}>
                    {!isConfirmingDelete && !isDeleting && (
                      <Button
                        style={{
                          flex: "1",
                        }}
                        className="w-full ml-1"
                        color={"failure"}
                        onClick={handleStartDelete}
                      >
                        Delete Account
                      </Button>
                    )}
                    {isConfirmingDelete && !isDeleting && (
                      <div className={"w-full"}>
                        <div className={"text-xs text-center mb-2"}>
                          Are you sure? This can't be undone.
                        </div>
                        <div className={"flex flex-row"}>
                          <Button
                            style={{
                              flex: "1",
                            }}
                            color="gray"
                            className={"flex-grow mr-2"}
                            onClick={handleCancelDelete}
                          >
                            Never mind
                          </Button>
                          <Button
                            style={{
                              flex: "1",
                            }}
                            color={"failure"}
                            className={"flex-grow"}
                            onClick={handleDelete}
                          >
                            Yes
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          )}
        </SubViewHeader>
      </SubView>
    </div>
  );
}
